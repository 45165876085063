import {Injectable} from "@angular/core";
import { Observable } from "rxjs";

import {
  IMaintainPersonUserAccessingRoleDto,
  KnoxApiService,
} from "@aex/security/shared";

@Injectable({
	providedIn: 'root',
})
export class RoleUserService {
	constructor(private readonly knoxApiService: KnoxApiService) {}

	public getManageRoleUsers(roleId: string, orgUnitId: string): Observable<IMaintainPersonUserAccessingRoleDto>{
    return this.knoxApiService.getRoleUserList(roleId, orgUnitId);
	}
}
