import { Injectable} from "@angular/core";
import { Observable } from "rxjs";

import {
  IMaintainInterOrgUnitAccessRoleDto,
  KnoxApiService,
} from "@aex/security/shared";

@Injectable({
	providedIn: 'root',
})
export class InterOrgUnitAccessRolesService {
	constructor(private readonly knoxApiService: KnoxApiService) {}

	public getManageInterOrgUnitRoles(interOrgUnitAccessId: string): Observable<IMaintainInterOrgUnitAccessRoleDto>{
    return this.knoxApiService.getInterOrgUnitRoleData(interOrgUnitAccessId);
	}

}
