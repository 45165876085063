import { IRoleDto, KnoxApiService } from '@aex/security/shared';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class GetSystemRolesResolver implements Resolve<IRoleDto[]> {
	
	constructor(private readonly knoxApiService: KnoxApiService) { }
	
	public resolve(): Observable<IRoleDto[]> {
		return this.knoxApiService.getSystemRoles();
	}
}
