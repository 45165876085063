import {
	IMaintainRoleDataDto,
	IRoleDto,
	KnoxApiService,
	SECURITY_ROUTE_PARAMS,
	SecurityCompanyService,
} from '@aex/security/shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

import { isNil } from "lodash";

@Injectable()
export class GetCompanyCustomerRolesResolver implements Resolve<IRoleDto[]> {

	constructor(
		private readonly knoxApiService: KnoxApiService,
		private readonly companyService: SecurityCompanyService) {
	}

	public resolve(): Observable<IRoleDto[]> {
		const companyId = this.companyService.getCompanyId();
		if (isNil(companyId))
			return of(new Array<IRoleDto>());

		return this.knoxApiService.getOrgUnitCustomerRoles(companyId);
	}

}

@Injectable()
export class GetMaintainCustomerRoleDataResolver implements Resolve<IMaintainRoleDataDto> {

	constructor(
		private readonly knoxApiService: KnoxApiService,
	) { }

	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainRoleDataDto> {
		const roleId = route.params[SECURITY_ROUTE_PARAMS.roleId];
		return this.knoxApiService.getManageCompanyCustomerRoleData(roleId);
	}

}
