import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-component-header',
	template: `
	<div class="flex flex-row justify-start items-center header-container gap-x-2">
		<button mat-icon-button (click)="back()" *ngIf="showBack">
			<fa-icon class="hoverIcon" [icon]="faArrowLeft"></fa-icon>
		</button>
		<div class="flex flex-col gap-y-2">
			<h4 class="mr-[0.5rem] page-title admin-nexus-heading">
				{{pageTitle}}
			</h4>
			<h5 class="entity-name admin-nexus-heading" *ngIf="entityName?.length > 0">
				{{entityName}}
			</h5>
		</div>
	</div>
	`,
	styleUrls: ['./component-header.component.scss'],
})
export class ComponentHeaderComponent {
	@Input() public pageTitle: string = '';
	@Input() public entityName: string = '';
	@Input() public showBack: boolean = true;

	public faArrowLeft = faArrowLeft;

	constructor(private readonly location: Location) {}

	public back(): void {
		this.location.back();
	}
}
