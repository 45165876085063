import { AuthService, HttpErrorService } from '@aex/shared/root-services';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getMetaData } from './param-meta-data';
import { APP_ROUTES } from './routing';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(
		protected readonly authService: AuthService,
		protected readonly router: Router,
		protected readonly httpError: HttpErrorService,
	) { }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
				catchError((error: HttpErrorResponse) => {
					const meta = getMetaData(request.params);

					let lError = error;

					if (!meta || meta.handleError !== false)
						lError = this.httpError.handleError(lError, meta && meta.handleError);
					else if (lError.status === HttpStatusCode.Forbidden)
						this.handleForbidden();

					return throwError(lError);
				}),
		);
	}

	protected handleForbidden(): void {
		this.authService.logout().subscribe();
		this.router.navigateByUrl(APP_ROUTES.coverage.path).then();
	}
}