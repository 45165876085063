import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree/*, Router */} from '@angular/router';
import { Observable } from 'rxjs';
import { KnoxSecurityService } from '../services/knox-security.service';
import { KnoxAllow } from 'libs/shared/common-lib/src/interfaces/general';

@Injectable({
  providedIn: 'root',
})
export class KnoxPermissionGuard implements CanActivate {
  constructor(private readonly knoxSecurityService: KnoxSecurityService /*, private router: Router */) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const knox = route.data['knox'];
    let knoxActions = knox['actions'];
    const knoxAllow : KnoxAllow = knox['knoxAllow'] || KnoxAllow.All;   // Default to 'all' if not specified
    const owningOrgUnits : string[] = knox['owningOrgUnits'] || [];     // Get owningOrgUnits array from route data

    console.log('Current route:', state.url); // Log current route URL

    // Convert single string to array if necessary
    if (typeof knoxActions === 'string')
      knoxActions = [knoxActions];

    switch (knoxAllow) {
      case KnoxAllow.All:
        return this.knoxSecurityService.userMayPerformAllActions(knoxActions, owningOrgUnits);
      case KnoxAllow.Any:
        return this.knoxSecurityService.userMayPerformAnyActions(knoxActions, owningOrgUnits);
      default:
        throw new Error(`Invalid permission mode: ${knoxAllow}`);
    }
  }
}
