import { ENVIRONMENT_CONFIG_TOKEN, IEnvironment, Operators } from '@aex/shared/common-lib';
import { BaseConfigService } from '@aex/shared/root-services';
import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { capitalize } from 'lodash';
import { zip } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	template: `
		<link rel="stylesheet" [href]="cssUrl" />
		<ngx-ui-loader></ngx-ui-loader>
		<router-outlet></router-outlet>
	`,
})
export class AppComponent {
	public cssUrl: SafeResourceUrl;
	public favIcon: HTMLLinkElement = document.querySelector('#appIcon');
	public themeColor: HTMLMetaElement = document.querySelector('#themeColor');

	constructor(
		public readonly configService: BaseConfigService,
		public sanitizer: DomSanitizer,
		private readonly titleService: Title,
		public router: Router,
		public location: Location,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) private readonly environment: IEnvironment,
	) {
		zip(
			this.configService.configValuesStream,
			this.configService.operatorStream,
		).pipe(
			filter(([x, y]) => !!x && !!y)).subscribe(([configValues, operator]) => {
				const theme = this.environment?.override?.theme ?? operator;
				if (theme !== Operators.AutomationExchange)
					this.cssUrl = sanitizer.bypassSecurityTrustResourceUrl(`${theme}.css`);
				else
					this.cssUrl = sanitizer.bypassSecurityTrustResourceUrl(`assets/styles/${theme}.css`);
				this.favIcon.href = `assets/img/${theme}-favicon.png`;
				this.titleService.setTitle(
					`${configValues.prettyName || capitalize(operator)} Admin Nexus`,
				);
				this.themeColor.content = configValues.themeColor;
			});
	}
}
