import { Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Resolve} from "@angular/router";
import { Observable} from "rxjs";

import { IMaintainInterOrgUnitAccessRoleDto } from "@aex/security/shared";
import { SECURITY_ROUTE_PARAMS } from "../helpers/security-route-parameters";
import { InterOrgUnitAccessRolesService } from "../services/inter-org-unit-access-roles.service";

@Injectable()
export class GetMaintainInterOrgUnitAccessRolesResolver implements Resolve<IMaintainInterOrgUnitAccessRoleDto> {
	constructor(
			private readonly interOrgUnitAccessRolesService: InterOrgUnitAccessRolesService,
	) {	}
	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainInterOrgUnitAccessRoleDto> {
		const interOrgUnitAccessId: string = route.params[SECURITY_ROUTE_PARAMS.interOrgUnitAccessId];
		return this.interOrgUnitAccessRolesService.getManageInterOrgUnitRoles(interOrgUnitAccessId);
	}
}
