import { HttpParamsPrimitive, ILoaderMetaData, ITimeoutMetaData, ParamsWithMetaData, Primitive } from '@aex/ngx-toolbox';
import { HttpParams } from '@angular/common/http';
import { AuthType } from './enums';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasMetaData(obj: any): obj is ParamMetaData {
	return obj.hasOwnProperty('meta');
}

export function getMetaData(params: HttpParams): IParamMetaData | undefined {
	return hasMetaData(params) ? params.meta : undefined;
}

export class ParamMetaData extends ParamsWithMetaData<IParamMetaData> {

	public static fromParams(params: HttpParamsPrimitive): ParamMetaData {
		return new ParamMetaData(null, params);
	}

	public static get allCount(): { count: string } { return {count: 'all'}; }

	public withAllCount(): ParamMetaData {
		return this.set('count', 'all');
	}

	// HttpParams is immutable - have to create a new object
	public override set(param: string, value: Primitive): ParamMetaData {
		this.params = {...(this.params || {}), [param]: value};
		return new ParamMetaData(this.meta, this.params);
	}

	public setPage(page: number = 1): ParamMetaData {
		return this.set('page', page);
	}

	public setCount(count: number = 10): ParamMetaData {
		return this.set('count', count);
	}

	public setQuery(query: string = ''): ParamMetaData {
		return this.set('q', query);
	}

	public setRefresh(refreshRequired: boolean): ParamMetaData {
		return this.set('refresh', refreshRequired);
	}
}

export interface IParamMetaData extends ILoaderMetaData, ITimeoutMetaData {
	// Either:
	//  .namespace - for error handling specifics
	//  .false - to disable error handling
	handleError?: string | boolean;

	authToken?: AuthConfig
}

export type AuthConfig = AuthType | string;

