<div class="flex flex-col h-screen">
	<header class="header">
		<div class="flex flex-row justify-between items-center">
			<div class="header-logo"></div>
		</div>
	</header>
	<main class="flex-1 overflow-hidden">
		<div class="flex justify-center">
			<div class="security-content">
				<router-outlet></router-outlet>
			</div>
		</div>
	</main>
	<footer class="flex flex-col justify-center items-center gap-y-2">
		<small class="copyright">
			All rights reserved. Integrated Network Management System © Automation Exchange 2024
		</small>
	</footer>
</div>