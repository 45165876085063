import { TItemModificationTag } from "@aex/shared/common-lib";
import { IActionDto } from "../interfaces/knox";

export class KnoxRegistrationActionHelper {

  public static RegisterAction(action : string, name : string, description : string): IActionDto
  {
    let actionDto : IActionDto = {
      item_modification_tag : TItemModificationTag.None,
      external_action_id : action,
      pretty_name : name,
      description : description,
      tags : ""
    }
    return actionDto;
  }

}