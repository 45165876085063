import { KnoxApiService } from '@aex/security/shared';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { IActionDto } from '../interfaces/knox';

@Injectable()
export class GetActionsResolver implements Resolve<IActionDto[]> {
	
	constructor(private readonly knoxApiService: KnoxApiService) {}

	public resolve(): Observable<IActionDto[]> {
		return this.knoxApiService.getAllActions();
	}
}
