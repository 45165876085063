import { KEY_USER_ID } from '@aex/shared/common-lib';
import { BaseConfigService } from '@aex/shared/root-services';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import {
	IInterOrgUnitAccessWithDataDto,
  IOrgUnitAccessOrgUnitWithDataDto,
	IOrgUnitDto,
} from '../interfaces/knox';
import { SecurityCompanyService } from '../root-services/security-company.service';
import { KnoxApiService } from '../services/knox-api.service';

@Injectable()
export class GetOrgUnitResolver implements Resolve<IOrgUnitDto> {

	constructor(
		private readonly companyService: SecurityCompanyService,
		private readonly knoxApiService: KnoxApiService,
	) { }

	public resolve(): Observable<IOrgUnitDto> {
		const companyId: string = this.companyService.getCompanyId();
		return this.knoxApiService.getOrgUnit(companyId);
	}
}

@Injectable()
export class GetUserOrgUnitsResolver implements Resolve<IOrgUnitDto[]> {

	constructor(
		private readonly configService: BaseConfigService,
		private readonly knoxApiService: KnoxApiService,
	) { }

	public resolve(): Observable<IOrgUnitDto[]> {
		const userId = this.configService.retrieve(KEY_USER_ID);
		if (isNil(userId))
			return of(new Array<IOrgUnitDto>());

		return this.knoxApiService.getUserOrgUnits(userId);
	}
}

@Injectable()
export class GetAccessedOrgUnitsResolver implements Resolve<IInterOrgUnitAccessWithDataDto[]> {
	constructor(
		private readonly companyService: SecurityCompanyService,
		private readonly knoxApiService: KnoxApiService,
	) { }

	public resolve(): Observable<IInterOrgUnitAccessWithDataDto[]> {
		const companyId = this.companyService.getCompanyId();
		return this.knoxApiService.getAccessedOrgUnits(companyId);
	}
}

@Injectable()
export class GetAccessorOrgUnitsResolver implements Resolve<IInterOrgUnitAccessWithDataDto[]> {

	constructor(
		private readonly companyService: SecurityCompanyService,
		private readonly knoxApiService: KnoxApiService,
	) { }

	public resolve(): Observable<IInterOrgUnitAccessWithDataDto[]> {
		const companyId = this.companyService.getCompanyId();
		return this.knoxApiService.getAccessorOrgUnits(companyId);
	}
}

@Injectable()
export class GetInheritedOrgUnitsResolver implements Resolve<IOrgUnitAccessOrgUnitWithDataDto[]> {

	constructor(
		private readonly companyService: SecurityCompanyService,
		private readonly knoxApiService: KnoxApiService,
	) { }

	public resolve(): Observable<IOrgUnitAccessOrgUnitWithDataDto[]> {
		const companyId = this.companyService.getCompanyId();
		return this.knoxApiService.getInheritedOrgUnits(companyId);
	}
}
