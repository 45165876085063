import { AuthService } from '@aex/shared/root-services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { APP_ROUTES } from './routing';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

	constructor(
			private readonly authService: AuthService,
	) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const loggedIn = this.authService.isLoggedIn;
		if (!loggedIn)
			this.authService.gotoLogin(state.url);
		return loggedIn;
	}
}

@Injectable({providedIn: 'root'})
export class MainRoutingGuard implements CanActivate {

	constructor(
			private readonly router: Router,
			private readonly authService: AuthService,
	) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const loggedIn = this.authService.isLoggedIn;
		// If already logged in, and no chosen route, go to dashboard
		if (loggedIn && (state.url === '/' || state.url.includes('landing')))
			this.router.navigateByUrl(APP_ROUTES.dashboard.path).then();
		// If not logged in, and no chosen path, go to coverage
		else if (!loggedIn && state.url === '/')
			this.router.navigateByUrl(APP_ROUTES.landing.path).then();
		return true;
	}
}
