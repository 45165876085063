import { KnoxApi } from "@aex/shared/apis";
import { ParamMetaData } from "@aex/shared/common-lib";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export class KnoxApiBaseService {

	constructor(protected readonly http: HttpClient) { }

	protected get<ResponseObject>(url: string): Observable<ResponseObject> {
		return this.http.get<ResponseObject>(
			KnoxApi.knoxUrlGeneral(url),
			{ params: new ParamMetaData({ handleError: 'knox'}) },
		);
	}

	protected put<RequestObject, ResponseObject>(url: string, data: RequestObject): Observable<ResponseObject> {
		return this.http.put<ResponseObject>(
			KnoxApi.knoxUrlGeneral(url),
			data,
			{ params: new ParamMetaData({ handleError: 'knox'}) },
		);
	}

	protected post<RequestObject, ResponseObject>(url: string, data: RequestObject): Observable<ResponseObject> {
		return this.http.post<ResponseObject>(
			KnoxApi.knoxUrlGeneral(url),
			data,
			{ params: new ParamMetaData({ handleError: 'knox'}) },
		);
	}

	protected delete<ResponseObject>(url: string): Observable<ResponseObject> {
		return this.http.delete<ResponseObject>(
			KnoxApi.knoxUrlGeneral(url),
			{ params: new ParamMetaData({ handleError: 'knox'}) },
		);
	}

}
