import { IYesNoDialogOptions } from '@aex/ngx-toolbox/lib/toolbox/yes-no.modal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { YesNoDialogComponent } from '../components/yes-no-dialog/yes-no-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class SecurityDialogService {

	constructor(public dialog: MatDialog) {}

	public openYesNoDialog(
		title: string,
		message: string,
		yesIsGood = true,
	): Observable<boolean> {
		const matdialogconf: MatDialogConfig = {
			data: {
				title,
				message,
				yesIsGood,
			} as IYesNoDialogOptions,
			width: '500px',
			disableClose: true,
		};

		return this.dialog.open(YesNoDialogComponent, matdialogconf).afterClosed();
	}
}
