
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
        MatIconModule,
        MatBadgeModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatExpansionModule,
        MatGridListModule,        
        MatInputModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatCardModule,
    ],
    exports: [
        MatIconModule,
        MatBadgeModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatExpansionModule,
        MatGridListModule,        
        MatInputModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatCardModule,
    ],
})
export class MaterialModule { }
