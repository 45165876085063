import { registerLocaleData } from '@angular/common';
import locale_en_ZA from '@angular/common/locales/en-ZA';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  private readonly validLocales: string[] = [
    'en-ZA',
  ];

  private readonly defaultLocaleId: string = 'en-US';

  constructor() {
    // Register all locales that are required for application
    // En-US is default
    // May need to update later
    // https://stackoverflow.com/questions/61035621/how-do-i-dynamically-import-locales-in-angular-9
    this.registerAppLocaleData();
  }

  public registerAppLocaleData() {
      registerLocaleData(locale_en_ZA);
  }

  public getLocale() : string {
    let localeId: string = navigator.language;

    // Check whether locale is within valid locale otherwise use default (en-us)
    if (this.validLocales.indexOf(localeId) === -1)
      localeId = this.defaultLocaleId;
    return localeId;
  }
}
