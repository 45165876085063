import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve} from '@angular/router';
import { Observable } from 'rxjs';

import {SECURITY_ROUTE_PARAMS} from "../helpers/security-route-parameters";

import {
	IMaintainPersonUserRoleDto,
	SecurityCompanyService,
} from "@aex/security/shared";
import {UserRoleService} from "../services/user-role.service";


@Injectable()
export class GetMaintainUserRolesDataResolver implements Resolve<IMaintainPersonUserRoleDto> {
	constructor(
			private readonly companyService: SecurityCompanyService,
			private readonly userRoleService: UserRoleService,
	) {	}

	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainPersonUserRoleDto> {
		const userId = route.params[SECURITY_ROUTE_PARAMS.userId];
		const companyId = this.companyService.getCompanyId();

		return this.userRoleService.getManageUserRoles(userId, companyId);
	}
}

