export const KNOX_ACTIONS = {
  // AdminAreaTickets : 'admin:area-tickets',
  // AdminAreas : 'admin:areas',
  // AdminBilling : 'admin:billing',
  // AdminCompanies : 'admin:companies',
  // AdminCustomFields : 'admin:custom-fields',
  // AdminCustomers : 'admin:customers',
  // AdminDevices : 'admin:devices',
  // AdminMonitoring : 'admin:monitoring',
  // AdminPaymentSystem : 'admin:payment-system',
  AdminPayments : 'admin:payments',
  // AdminPeople : 'admin:people',
  // AdminPremises : 'admin:premises',
  // AdminProducts : 'admin:products',
  AdminSecurity : 'admin:security',
  // AdminServices : 'admin:services',
  // AdminSystem : 'admin:system',
	// AdminZms : 'admin:zms',
  // AdminToBeAdvised : 'admin:to-be-advised',
  // AdminToBeDeleted : 'admin:to-be-deleted',
  // AdminWorkOrders : 'admin:work-orders',
  // ApplyPromotionsAndDiscounts : 'apply:promotions-and-discounts',
  // AssignPremiseDeviceSerial : 'assign:premise-device-serial',
  // BulkUpdateWorkOrders : 'bulk-update:work-orders',
	CaptureRefunds : 'capture:refunds',
  // ConfigureDevices : 'configure:devices',
  // CreateOffline : 'create:offline',
  // DeleteCompanies : 'delete:companies',
  // DeleteServices : 'delete:services',
  // DevelopNms : 'develop:nms',
  // DiscoverDevices : 'discover:devices',
  // ExportWorkOrders : 'export:work-orders',
  // ImportServices : 'import:services',
  // LogSupportTickets : 'log:support-tickets',
  // ManageApplicationConfigs : 'manage:application-configs',
  // ManageAreaTickets : 'manage:area-tickets',
  // ManageAreas : 'manage:areas',
  // ManageAssetClass : 'manage:asset-class',
  // ManageAssets : 'manage:assets',
  // ManageBilling : 'manage:billing',
  // ManageBulkServiceState : 'manage:bulk-service-state',
  // ManageBulkServices : 'manage:bulk-services',
  // ManageBulkWorkOrders : 'manage:bulk-work-orders',
  // ManageClientPremises : 'manage:client-premises',
  ManageCompanies : 'manage:companies',
  // ManageCoreStatus : 'manage:core-status',
  // ManageCustomerDocuments : 'manage:customer-documents',
  ManageCustomers : 'manage:customers',
  // ManageDevice : 'manage:device',
  // ManageDeviceConfig : 'manage:device-config',
  // ManageDeviceInstallations : 'manage:device-installations',
  // ManageDeviceMonitoring : 'manage:device-monitoring',
  // ManageDevices : 'manage:devices',
  // ManageDocuments : 'manage:documents',
  // ManageEventCorrelation : 'manage:event-correlation',
  // ManageFaultWorkOrders : 'manage:fault-work-orders',
  // ManageFsan : 'manage:fsan',
  // ManageI18n : 'manage:i18n',
  // ManageInstallationWorkOrders : 'manage:installation-work-orders',
  // ManageInterconnects : 'manage:interconnects',
  // ManageLoadShedding : 'manage:load-shedding',
  // ManageMarketing : 'manage:marketing',
  // ManageNetworkNodes : 'manage:network-nodes',
  // ManageNetworkService : 'manage:network-service',
  // ManageNetworks : 'manage:networks',
  // ManageNmsConfig : 'manage:nms-config',
  // ManageNmsOperators : 'manage:nms-operators',
  // ManageNotifications : 'manage:notifications',
  ManagePayments : 'manage:payments',
  ManagePeople : 'manage:people',
  // ManagePreOrderWorkOrders : 'manage:pre-order-work-orders',
  // ManagePredefinedPremises : 'manage:predefined-premises',
  // ManagePremises : 'manage:premises',
  // ManageProducts : 'manage:products',
  // ManagePromotionsAndDiscounts : 'manage:promotions-and-discounts',
  // ManageRepairWorkOrders : 'manage:repair-work-orders',
  // ManageRoles : 'manage:roles',
  // ManageSalesChannels : 'manage:sales-channels',
  ManageSecurity : 'manage:security',
  // ManageServiceChanges : 'manage:service-changes',
  // ManageServiceNetworkConfiguration : 'manage:service-network-configuration',
  // ManageServiceState : 'manage:service-state',
  // ManageServiceStatus : 'manage:service-status',
  // ManageServices : 'manage:services',
  // ManageSnmpConfig : 'manage:snmp-config',
  // ManageSpeedTests : 'manage:speed-tests',
  // ManageSupportConfiguration : 'manage:support-configuration',
  // ManageSystemConfiguration : 'manage:system-configuration',
  // ManageSystemSettings : 'manage:system-settings',
  // ManageTeam : 'manage:team',
  // ManageTemplates : 'manage:templates',
  ManageUsers : 'manage:users',
  // ManageVlans : 'manage:vlans',
  // ManageWorkOrderEvents : 'manage:work-order-events',
  // ManageWorkOrderPricing : 'manage:work-order-pricing',
  // ManageWorkOrders : 'manage:work-orders',
  // MonitorDevices : 'monitor:devices',
  // ProgressWorkOrders : 'progress:work-orders',
  // ReadApplicationConfig : 'read:application-config',
  // ReadAreaTickets : 'read:area-tickets',
  // ReadAreas : 'read:areas',
  // ReadAssetClass : 'read:asset-class',
  // ReadAssets : 'read:assets',
  // ReadBilling : 'read:billing',
  // ReadBillingReports : 'read:billing-reports',
  // ReadCalendar : 'read:calendar',
  // ReadClientPremises : 'read:client-premises',
  // ReadCompanies : 'read:companies',
  // ReadCustomFieldConfiguration : 'read:custom-field-configuration',
  // ReadCustomerDocuments : 'read:customer-documents',
  ReadCustomers : 'read:customers',
  // ReadDeviceConfig : 'read:device-config',
  // ReadDeviceMonitoring : 'read:device-monitoring',
  // ReadDeviceStatus : 'read:device-status',
  // ReadDeviceWifi : 'read:device-wifi',
  ReadDevices : 'read:devices',
  // ReadDocuments : 'read:documents',
  // ReadEventCorrelation : 'read:event-correlation',
  // ReadFaultWorkOrders : 'read:fault-work-orders',
  // ReadI18n : 'read:i18n',
  // ReadInstallationWorkOrders : 'read:installation-work-orders',
  // ReadIntegratedNetwork : 'read:integrated-network',
  // ReadInterconnects : 'read:interconnects',
  // ReadLoadShedding : 'read:load-shedding',
  // ReadMarketing : 'read:marketing',
  // ReadNetworkNodes : 'read:network-nodes',
  // ReadNetworkService : 'read:network-service',
  // ReadNetworks : 'read:networks',
  // ReadNmsConfig : 'read:nms-config',
  // ReadNmsOperators : 'read:nms-operators',
  // ReadNotifications : 'read:notifications',
  // ReadPaymentConfig : 'read:payment-config',
  // ReadPayments : 'read:payments',
  // ReadPeople : 'read:people',
  // ReadPreOrderWorkOrders : 'read:pre-order-work-orders',
  // ReadPredefinedPremises : 'read:predefined-premises',
  // ReadPremises : 'read:premises',
  // ReadProducts : 'read:products',
  // ReadPromotionsAndDiscounts : 'read:promotions-and-discounts',
  // ReadRepairWorkOrders : 'read:repair-work-orders',
  // ReadReport : 'read:report',
  // ReadRoles : 'read:roles',
  // ReadSalesChannels : 'read:sales-channels',
  ReadSecurity : 'read:security',
  // ReadServiceChanges : 'read:service-changes',
  // ReadServiceState : 'read:service-state',
  // ReadServices : 'read:services',
  // ReadSnmpConfig : 'read:snmp-config',
  // ReadSpeedTests : 'read:speed-tests',
  // ReadSupportConfiguration : 'read:support-configuration',
  // ReadSystemConfiguration : 'read:system-configuration',
  // ReadSystemSettings : 'read:system-settings',
  // ReadTeam : 'read:team',
  // ReadTemplates : 'read:templates',
  // ReadUsers : 'read:users',
  // ReadVlans : 'read:vlans',
  // ReadWorkOrderConfig : 'read:work-order-config',
  // ReadWorkOrderEvents : 'read:work-order-events',
  // ReadWorkOrderPricing : 'read:work-order-pricing',
  // ReadWorkOrderSchedule : 'read:work-order-schedule',
  // ReadWorkOrders : 'read:work-orders',
  // ReportWorkOrders : 'report:work-orders',
  // SendNotifications : 'send:notifications',
  // UpdateWorkOrderFields : 'update:work-order-fields',
  // UploadWorkOrderDocuments : 'upload:work-order-documents',
  // WriteIntegratedNetwork : 'write:integrated-network',
  
  // ShowPortalAdvanceFilter : 'show:portal-advance-filter',
  // ShowPortalProviders : 'show:portal-providers',

};
