import {Injectable} from "@angular/core";
import {
  Observable,
} from "rxjs";
import {
  IMaintainPersonUserRoleDto,
  KnoxApiService,
} from "@aex/security/shared";

@Injectable({
	providedIn: 'root',
})
export class UserRoleService {

	constructor(private readonly knoxApiService: KnoxApiService) {
	}

	public getManageUserRoles(userId: string, orgUnitId: string): Observable<IMaintainPersonUserRoleDto> {
  	return this.knoxApiService.getManageUserRoleData(userId, orgUnitId)
	}

}
