export enum FieldDataType {
	Toggle = 'Toggle',
	Text = 'Text',
	Number = 'Number',
	Date = 'Date',
	Boolean = 'Boolean',
}

export const TOGGLE_FIELD_DATA_TYPE = FieldDataType.Toggle;
export const TEXT_FIELD_DATA_TYPE = FieldDataType.Text;
export const NUMBER_FIELD_DATA_TYPE = FieldDataType.Number;
export const DATE_FIELD_DATA_TYPE = FieldDataType.Date;

export interface DataGridViewerColumn {
	fieldName: string;
	displayName: string;
	dataType: FieldDataType;
	class?: string;
	width?: string;
	enabled?: boolean;
	enableColumnFilter?: boolean;
}
