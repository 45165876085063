export const LOGIN_RESPONSE_CODE = {
	Successful :200,
	InitiatePasswordChange: 203,
	EmailVerificationRequired :212,
	TwoFactorLoginResponseRequired :242,
	TwoFactorAuthInitRequired :243,
	UserNotFoundOrPasswordWasIncorrect :404,
	UserHasExpired :409,
	UserIsLocked :423,
}


export const TWO_FACTOR_LOGIN_RESPONSE_CODE = {
	Successful :200,
	InitiatePasswordChange: 203,
	EmailVerificationRequired :212,
	TwoFactorIdHasExpired :404,
	UserInActionPreventingLogin :409,
	UserHasExpired :410,
	InvalidCode :421,
}
