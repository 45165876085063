import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IMayPerformActionRequestDto,
  IMayPerformActionRequestList,
  IMayPerformActionResponseDto,
  IMayPerformActionResponseList,
} from '../interfaces/knox-security';
import { KnoxSecurityApiService } from './knox-security-api.service';
import {AuthService} from "@aex/shared/root-services";

@Injectable({
  providedIn: 'root',
})
export class KnoxSecurityService {

  constructor(
			private readonly authService: AuthService,
			private readonly knoxSecurityApiService: KnoxSecurityApiService,
  ) {}

	private getJwtUserId(): string {
		return this.authService.getUserIdClaim();
	}

  // Check if user may perform a single action
  public userMayPerformAction(knoxAction: string, owningOrgUnits?: string[]): Observable<boolean> {
		const userId = this.getJwtUserId();
		if (userId === undefined) {}
    return this.userMayPerformAllActions([knoxAction], owningOrgUnits);
  }

  public userMayPerformActions(knoxActions: string[], owningOrgUnits?: string[]): Observable<IMayPerformActionResponseList> {
	  const actionRequestList = this.buildActionRequestList(knoxActions, owningOrgUnits);
    const actionRequest: IMayPerformActionRequestList = {
      may_perform_action_request_list: actionRequestList,
    };
    return this.knoxSecurityApiService
      .userMayPerformActions(actionRequest)
      .pipe(
        map(response => response),
      );
  }

  // Check if user may perform all actions
  public userMayPerformAllActions(knoxActions: string[], owningOrgUnits: string[] = null): Observable<boolean> {
    const actionRequestList = this.buildActionRequestList(knoxActions, owningOrgUnits);
    const actionRequest: IMayPerformActionRequestList = {
      may_perform_action_request_list: actionRequestList,
    };
    return this.knoxSecurityApiService.userMayPerformActions(actionRequest).pipe(
      map(actionResponses =>
        actionResponses.may_perform_action_responses.every(actionResponse => this.isAuthorised(actionResponse)),
      ),
    );
  }

  // Check if user may perform any actions
  public userMayPerformAnyActions(knoxActions: string[], owningOrgUnits: string[] = null): Observable<boolean> {
    const actionRequestList = this.buildActionRequestList(knoxActions, owningOrgUnits);
    const actionRequest: IMayPerformActionRequestList = {
      may_perform_action_request_list: actionRequestList,
    };

    return this.knoxSecurityApiService.userMayPerformActions(actionRequest).pipe(
      map(actionResponses =>
        actionResponses.may_perform_action_responses.some(actionResponse => this.isAuthorised(actionResponse))),
    );
  }

  // Build the IMayPerformActionRequestDto list for the API
  private buildActionRequestList(knoxActions: string[], owningOrgUnits?: string[]): IMayPerformActionRequestDto[] {
    return knoxActions.map(permission => this.buildActionRequest(permission, owningOrgUnits));
  }

  // Build a single IMayPerformActionRequestDto
  private buildActionRequest(permission: string, owning_org_units : string[]): IMayPerformActionRequestDto {
    return {
      action_permission: permission,
      owning_org_units: owning_org_units,
    };
  }

  // Check if the action response is authorised
  public isAuthorised(actionResponse: IMayPerformActionResponseDto): boolean {
    return actionResponse.success && actionResponse.may_perform_action;
  }

}
