import {
  Expose,
  Exclude,
  Type,
} from 'class-transformer';
import {
  IOrgUnitDto,
  IOrgUnitTypeDto,
  IRoleDto,
  IRoleRoleDto,
  IRoleActionInfoDto,
  IInterOrgUnitAccessDto,
  IPersonUserDto,
  IUserDto,
  IApplicationDto,
} from './knox';
import { IBaseItem, TItemModificationTag, UserType } from "@aex/shared/common-lib";

//#region --- Knox API CLASSES used by Security Rules ---

export class BaseItem implements IBaseItem {
  public id : string;
  public item_modification_tag : TItemModificationTag;
}

export abstract class UserDto extends BaseItem implements IUserDto {
  public user_type: UserType;
	public user_status: string;
  public external_auth_id: string;
}

export class PersonUserDto extends UserDto implements IPersonUserDto {
	public email: string;
	public person_id: string; //uuid
	public first_name: string;
	public last_name: string;
	public mobile: string;
	public password: string;
  public verify_email: boolean;
	public change_password: boolean;
	public enable_two_factor: boolean;
}

export class ApplicationDto extends UserDto implements IApplicationDto {
	public name: string;
}

export class RoleDto extends BaseItem implements IRoleDto {
	public name: string;
	public description: string;
	public owner_org_unit_id: string | null; //uuid
	public is_super_user: boolean;
  public is_org_unit_type_superset: boolean;
	public tags: string;

  // Set item_modification_tag based on changes
  public setItemModificationTag(oldRole : IRoleDto): boolean {
    // Compare roles
    const isUpdated =
      (this.name !== oldRole.name) ||
      (this.description !== oldRole.description) ||
      (this.owner_org_unit_id !== oldRole.owner_org_unit_id);

    if (isUpdated)
      this.item_modification_tag = TItemModificationTag.Updated;

    return isUpdated;
  }

  public assignRole(role: IRoleDto): void {
    this.id = role.id;
    this.name = role.name;
    this.description = role.description;
    this.owner_org_unit_id = role.owner_org_unit_id;
    this.is_super_user = role.is_super_user;
    this.is_org_unit_type_superset = role.is_org_unit_type_superset;
    this.tags = role.tags;
  }

  public cloneRole(): IRoleDto {
    const clonerole = new RoleDto();
    clonerole.assignRole(this);
    return clonerole;
  }

}

export class RoleRoleDto extends BaseItem implements IRoleRoleDto {
  public accessor_role_id: string; //uuid
  public accessed_role_id: string; //uuid
 	public is_negative: boolean;
}

export class OrgUnitTypeDto extends BaseItem implements IOrgUnitTypeDto {
	public org_unit_type_name: string;
	public external_org_unit_type_id: string;
}

export class OrgUnitDto extends BaseItem implements IOrgUnitDto {
	public external_org_unit_id: string; //uuid
	public org_unit_name: string;
	public tags: string;
}

export class InterOrgUnitAccessDto extends BaseItem implements IInterOrgUnitAccessDto {
  public accessor_org_unit_id: string; //uuid
  public accessed_org_unit_id: string; //uuid
}

//#endregion --- Knox API CLASSES used by Security Rules ---

//#region --- Security Rule Interfaces ---

//#region --- Base Security Rule Interfaces ---

export interface ISecurityRuleBase extends IBaseItem{
  // Properties used for display-purposes
  get security_rule_name() : string;
  get security_rule_description() : string;
  get security_rule_accessed_entity_id() : string;

  // Have_rule is true if record is Selected
  get have_rule() : boolean;
  // If true, the rule is revoked
  get is_negative(): boolean | null;
  // is selected rule and is negative or positive
  get have_negative_rule() : boolean;
  get have_positive_rule() : boolean;
  // Original rule values on server
  get orig_have_explicit_rule() : boolean;
  set orig_have_explicit_rule(value : boolean);
  // Original rule for Is Negative
  get orig_explicit_is_negative(): boolean | null;
  set orig_explicit_is_negative(value : boolean | null);

  // Current rule
  get have_explicit_rule() : boolean;
  set have_explicit_rule(value : boolean);

  // Is Negative
  get explicit_is_negative() : boolean | null;
  set explicit_is_negative(value : boolean | null);

  // Is rule selected, but not via explicit rule
  get have_non_explicit_rule() : boolean;

  get editable_is_negative() : boolean;
  set editable_is_negative(value : boolean);
}

export interface INonInheritableSecurityRuleBase extends ISecurityRuleBase {
}

export interface IInheritableSecurityRuleBase extends ISecurityRuleBase {
  get have_inherited_rule() : boolean;
  set have_inherited_rule(value : boolean);

  get inherited_is_negative() : boolean | null;
  set inherited_is_negative(value : boolean | null);
}

//#endregion

//#region --- Action Security Rule Interfaces ---

//#region --- Base Action Security Rule Interfaces ---

export interface IEntityActionSecurityRule extends INonInheritableSecurityRuleBase {
  accessor_entity_id : string;
  accessed_action_id : string;
  accessed_action_external_id : string;
  accessed_action_pretty_name : string;
  accessed_action_description : string;
}

//#endregion --- Base Action Security Rule Interfaces ---

//#region --- Entity-Action Security Rule Interfaces ---

export interface IRoleActionSecurityRuleDto extends IEntityActionSecurityRule {
  accessor_role_id : string;
}

export interface IOrgUnitTypeActionSecurityRuleDto extends IEntityActionSecurityRule {
  accessor_org_unit_type_id : string;
}

//#endregion --- Entity-Action Security Rule Interfaces ---

//#endregion --- Action Security Rule Interfaces ---

//#region --- Role Security Rule Interfaces ---

//#region --- Base Role Security Rule Interfaces ---

export interface IEntityAccessingRoleSecurityRule extends IInheritableSecurityRuleBase {
  accessed_role_id : string;

  get accessor_entity_id() : string;
  set accessor_entity_id(value : string);
}

export interface IEntityRoleSecurityRule extends IEntityAccessingRoleSecurityRule {
  accessed_role_name : string;
  accessed_role_description : string;
  accessed_role_owner_org_unit_id? : string;
  accessed_role_is_super_user : boolean;
  accessed_role_roles : IRoleRoleDto[];

  get is_custom_role() : boolean;
  get is_system_role() : boolean;
}

//#endregion --- Base Role Security Rule Interfaces ---

//#region --- Entity-Role Security Rule Interfaces ---

export interface IOrgUnitTypeRoleSecurityRuleDto extends IEntityRoleSecurityRule {
  accessor_org_unit_type_id : string;
}

export interface IInterOrgUnitAccessRoleSecurityRuleDto extends IEntityRoleSecurityRule {
  accessor_inter_org_unit_access_id : string;
}

export interface IRoleRoleSecurityRuleDto extends IEntityRoleSecurityRule {
  accessor_role_id : string;
}

export interface IUserRoleSecurityRuleDto extends IEntityRoleSecurityRule {
  accessor_user_id : string;
}

//#endregion --- Entity-Role Security Rule Interfaces ---

//#region --- Entity-Accessing-Role Security Rule Interfaces ---

export interface IUserAccessingRoleSecurityRuleDto extends IInheritableSecurityRuleBase {
  accessor_user_id : string;
}

export interface IPersonUserAccessingRoleSecurityRuleDto extends IUserAccessingRoleSecurityRuleDto {
  accessor_user_email : string;
}

export interface IApplicationAccessingRoleSecurityRuleDto extends IUserAccessingRoleSecurityRuleDto {
  accessor_application_name : string;
}

//#endregion --- Entity-Accessing-Role Security Rule Interfaces ---

//#endregion --- Role Security Rule Interfaces ---

//#region --- OrgUnit Security Rule Interfaces ---

//#region --- Base OrgUnit Security Rule Interfaces ---

export interface IEntityAccessingOrgUnitSecurityRule extends INonInheritableSecurityRuleBase {
  accessed_org_unit_id : string;

  get accessor_entity_id() : string;
  set accessor_entity_id(value : string);

  // Helper functions
  SecurityRuleHasOrgUnitType(orgUnitTypeName : string) : boolean;
}

export interface IEntityOrgUnitSecurityRule extends IEntityAccessingOrgUnitSecurityRule {
  accessed_org_unit_name : string;
  accessed_org_unit_org_unit_type_description : string;
  accessed_org_unit_types : string[];

  // Helper functions
  AccessedOrgUnitHasOrgUnitType(orgUnitTypeName : string) : boolean;
}

//#endregion --- Base OrgUnit Security Rule Interfaces ---

//#region --- Entity OrgUnit Security Rule Interfaces ---
// List of accessed OrgUnits accessible to the specified accessor OrgUnit
export interface IOrgUnitOrgUnitSecurityRuleDto extends IEntityOrgUnitSecurityRule {
  accessor_org_unit_id : string;
}

export interface IPersonUserOrgUnitSecurityRuleDto extends IEntityOrgUnitSecurityRule {
  accessor_user_id : string;
}

export interface IApplicationOrgUnitSecurityRuleDto extends IEntityOrgUnitSecurityRule {
  accessor_user_id : string;
}

//#endregion --- Entity-OrgUnit Security Rule Interfaces ---

//#region --- Entity-Accessing-OrgUnit Security Rule Interfaces ---
// List of OrgUnit Accessors to the specified accessed OrgUnit
export interface IOrgUnitAccessingOrgUnitSecurityRuleDto extends IEntityAccessingOrgUnitSecurityRule {
  accessor_org_unit_id : string;
  accessor_org_unit_name : string;
  accessor_org_unit_org_unit_type_description : string;
  accessor_org_unit_types : string[];

  // Helper functions
  AccessorOrgUnitHasOrgUnitType(orgUnitTypeName : string) : boolean;
}

//#endregion --- Entity-Accessing-OrgUnit Security Rule Interfaces ---

//#endregion --- OrgUnit Security Rule Interfaces ---

//#endregion --- Security Rule Interfaces ---

//#region --- Base Security Rule CLASSES ---

export abstract class SecurityRuleBaseDto extends BaseItem implements ISecurityRuleBase {

  // Property Fields
  @Exclude()
  private _origHaveExplicitRule : boolean;

  @Exclude()
  private _origExplicitIsNegative : boolean;

  @Exclude()
  private _haveExplicitRule : boolean;

  @Exclude()
  private _explicitIsNegative : boolean;

  public get security_rule_name() : string {
    return this.GetSecurityRuleName();
  }

  public get security_rule_description() : string {
    return this.GetSecurityRuleDescription();
  }

  public get security_rule_accessed_entity_id() : string {
    return this.GetSecurityRuleAccessedEntityId();
  }

  // Properties specific to rule
  public get have_rule() : boolean {
    return this.GetHaveRule();
  }

  public get is_negative() : boolean | null {
    return this.GetIsNegative();
  }

  public get have_negative_rule() : boolean {
    return this.have_rule && this.is_negative;
  }
  public get have_positive_rule() : boolean {
    return this.have_rule && !this.is_negative;
  }

  @Expose()
  public get orig_have_explicit_rule() : boolean {
    return this._origHaveExplicitRule;
  }
  public set orig_have_explicit_rule(value: boolean) {
    if (value !== this._origHaveExplicitRule)
    {
      this._origHaveExplicitRule = value;
      if (!value)
        this._origExplicitIsNegative = false;
      this.UpdateItemModificationTag();
    }
  }

  @Expose()
  public get orig_explicit_is_negative() : boolean | null {
    if (!this._origHaveExplicitRule)
      return null;
    return this._origExplicitIsNegative;
  }
  public set orig_explicit_is_negative(value: boolean | null) {
    if (value === this.orig_explicit_is_negative)
      return;

    if (value === null) {
      this._origHaveExplicitRule = false;
      this._origExplicitIsNegative = false;
    }
    else {
      this._origHaveExplicitRule = true;
      this._origExplicitIsNegative = value;
    }
    this.UpdateItemModificationTag();
  }

  @Expose()
  public get have_explicit_rule() : boolean {
    return this._haveExplicitRule;
  }
  public set have_explicit_rule(value: boolean) {
    if (value === this._haveExplicitRule)
      return;

    this._haveExplicitRule = value;
    if (!value)
      this._explicitIsNegative = false;
    else

      if (this._origHaveExplicitRule)
        this._explicitIsNegative = this._origExplicitIsNegative;

    this.UpdateItemModificationTag();
  }

  @Expose()
  public get explicit_is_negative() : boolean | null {
    if (this._haveExplicitRule)
      return this._explicitIsNegative;
    return null;
  }
  public set explicit_is_negative(value: boolean | null) {
    if (value === this.explicit_is_negative)
      return;

    if (value === null) {
      this._haveExplicitRule = false;
      this._explicitIsNegative = false;
    }
    else {
      this._haveExplicitRule = true;
      this._explicitIsNegative = value;
    }
    this.UpdateItemModificationTag();
  }

  public get have_non_explicit_rule() : boolean {
    return this.GetHaveNonExplicitRule();
  };

  public get editable_is_negative() : boolean | null {
    return this.is_negative;
  }

  public set editable_is_negative(value : boolean | null) {
    this.SetIsNegative(value);
  }

  protected abstract GetHaveRule() : boolean;
  protected abstract GetIsNegative() : boolean | null;
  protected abstract GetSecurityRuleAccessedEntityId() : string;

  protected GetHaveNonExplicitRule() : boolean
  {
    return false;
  }

  protected abstract SetIsNegative(value : boolean | null) : void;

  protected abstract GetSecurityRuleName() : string;
  protected abstract GetSecurityRuleDescription() : string;

  private UpdateItemModificationTag(): void {
    if (this.orig_have_explicit_rule === this.have_explicit_rule)
      if (this.orig_have_explicit_rule &&  (this.orig_explicit_is_negative !== this.explicit_is_negative))
        this.item_modification_tag = TItemModificationTag.Updated;
      else
        this.item_modification_tag = TItemModificationTag.None;
    else
      this.item_modification_tag = this.orig_have_explicit_rule
        ? TItemModificationTag.Deleted
        : TItemModificationTag.Inserted;
  }

}

export abstract class NonInheritableSecurityRuleBaseDto extends SecurityRuleBaseDto implements INonInheritableSecurityRuleBase {
  // No additional properties or methods in this example
  protected override GetHaveRule() : boolean {
    return this.have_explicit_rule;
  }

  protected override GetIsNegative() : boolean | null {
    return this.explicit_is_negative;
  }

  protected override SetIsNegative(value : boolean) : void
  {
    this.explicit_is_negative = value;
  }
}

export abstract class InheritableSecurityRuleBaseDto extends SecurityRuleBaseDto implements IInheritableSecurityRuleBase {
  @Exclude()
  private _haveInheritedRule : boolean;

  @Exclude()
  private _inheritedIsNegative : boolean;

  // Properties specific to rule
  @Expose()
  public get have_inherited_rule() : boolean {
    return this._haveInheritedRule;
  }
  public set have_inherited_rule(value: boolean) {
    this._haveInheritedRule = value;
    if (!value)
      this._inheritedIsNegative = false;
  }

  @Expose()
  public get inherited_is_negative() : boolean | null {
    if (this._haveInheritedRule)
      return this._inheritedIsNegative;
    return null;
  }
  public set inherited_is_negative(value: boolean | null) {
    if (value === null) {
      this._haveInheritedRule = false;
      this._inheritedIsNegative = false;
    }
    else {
      this._haveInheritedRule = true;
      this._inheritedIsNegative = value;
    }
  }

  protected override GetHaveRule() : boolean {
    return this.have_explicit_rule || this.have_inherited_rule;
  }

  protected override GetIsNegative() : boolean | null {
    if (this.have_explicit_rule)
      return this.explicit_is_negative;
    if (this.have_inherited_rule)
      return this.inherited_is_negative;
    return null;
  }
  protected override SetIsNegative(value : boolean) : void
  {
    if (this.have_inherited_rule &&
        (value === this.inherited_is_negative))
      this.have_explicit_rule = false;
    else
      this.explicit_is_negative = value
  }

  protected override GetHaveNonExplicitRule() : boolean
  {
    return !this.have_explicit_rule && this.have_inherited_rule;
  }

}

//#endregion

//#region --- Security Rule CLASSES ---

//#region --- Action Security Rule CLASSES ---

//#region --- Base Action Security Rule CLASSES ---

export abstract class EntityRoleActionSecurityRuleDto extends NonInheritableSecurityRuleBaseDto implements IEntityActionSecurityRule {
  // Properties specific to rule
  public accessed_action_id: string;
  public accessed_action_external_id: string;
  public accessed_action_pretty_name: string;
  public accessed_action_description: string;

  public get accessor_entity_id() : string {
    return this.GetAccessorEntityId()
  };

  public set accessor_entity_id(value: string) {
    this.SetAccessorEntityId(value);
  }

  protected abstract GetAccessorEntityId(): string;
  protected abstract SetAccessorEntityId(value : string) : void;

  protected override GetSecurityRuleName() : string
  {
    return this.accessed_action_pretty_name;
  }

  protected override GetSecurityRuleDescription() : string
  {
    return this.accessed_action_description;
  }

  protected override GetSecurityRuleAccessedEntityId() : string
  {
    return this.accessed_action_id;
  }
}

//#endregion --- Base Action Security Rule CLASSES ---

//#region --- Entity-Action Security Rule CLASSES ---

export class RoleActionSecurityRuleDto extends EntityRoleActionSecurityRuleDto implements IRoleActionSecurityRuleDto {
  public accessor_role_id: string;

  protected override GetAccessorEntityId(): string {
    return this.accessor_role_id;
  }
  protected override SetAccessorEntityId(value: string) : void {
    this.accessor_role_id = value;
  }
}

export class OrgUnitTypeActionSecurityRuleDto extends EntityRoleActionSecurityRuleDto implements IOrgUnitTypeActionSecurityRuleDto {
  public accessor_org_unit_type_id: string;

  protected override GetAccessorEntityId(): string {
    return this.accessor_org_unit_type_id;
  }
  protected override SetAccessorEntityId(value: string) : void {
    this.accessor_org_unit_type_id = value;
  }

}

//#endregion --- Entity-Action Security Rule CLASSES ---

//#endregion --- Action Security Rule CLASSES ---

//#region --- Role Security Rule CLASSES ---

//#region --- Base Role Security Rule CLASSES ---

export abstract class EntityAccessingRoleSecurityRuleDto extends InheritableSecurityRuleBaseDto implements IEntityAccessingRoleSecurityRule {
  public accessed_role_id: string;

  public get accessor_entity_id(): string {
    return this.GetAccessorEntityId();
  }
  public set accessor_entity_id(value: string) {
    this.SetAccessorEntityId(value);
  }

  protected abstract GetAccessorEntityId(): string;
  protected abstract SetAccessorEntityId(value: string) : void;

  protected override GetSecurityRuleAccessedEntityId() : string
  {
    return this.accessed_role_id;
  }
}

export abstract class EntityRoleSecurityRuleDto extends EntityAccessingRoleSecurityRuleDto implements IEntityRoleSecurityRule {
  // Properties used for display-purposes
  public accessed_role_name : string = '';
  public accessed_role_description : string = '';
  public accessed_role_owner_org_unit_id : string | null = null;
  public accessed_role_is_super_user : boolean = false;

  // Properties specific to inherited role access
  @Type(() => RoleRoleDto)
  public accessed_role_roles : IRoleRoleDto[] = [];

  public get is_custom_role() : boolean {
    return this.accessed_role_owner_org_unit_id !== null;
  }
  public get is_system_role() : boolean {
    return !this.is_custom_role;
  }

  protected override GetSecurityRuleName() : string
  {
    return this.accessed_role_name;
  }

  protected override GetSecurityRuleDescription() : string
  {
    return this.accessed_role_description;
  }
}

//#endregion --- Base Role Security Rule CLASSES ---

//#region --- Entity-Role Security Rule CLASSES ---

export class RoleRoleSecurityRuleDto extends EntityRoleSecurityRuleDto implements IRoleRoleSecurityRuleDto{
  public accessor_role_id: string;

  protected override GetAccessorEntityId(): string
  {
    return this.accessor_role_id;
  }

  protected override SetAccessorEntityId(value : string) : void
  {
    this.accessor_role_id = value;
  }
}

// Roles accessible to the specified Role
export class UserRoleSecurityRuleDto extends EntityRoleSecurityRuleDto implements IUserRoleSecurityRuleDto {
  public accessor_user_id : string;

  protected override GetAccessorEntityId(): string {
    return this.accessor_user_id;
  }
  protected override SetAccessorEntityId(value: string): void {
    this.accessor_user_id = value;
  }
}

// Roles accessible to the OrgUnitType
export class OrgUnitTypeRoleSecurityRuleDto extends EntityRoleSecurityRuleDto implements IOrgUnitTypeRoleSecurityRuleDto {
  public accessor_org_unit_type_id: string;

  protected override GetAccessorEntityId(): string
  {
    return this.accessor_org_unit_type_id;
  }

  protected override SetAccessorEntityId(value : string) : void
  {
    this.accessor_org_unit_type_id = value;
  }
}

// Roles accessible to the OrgUnit
export class InterOrgUnitAccessRoleSecurityRuleDto extends EntityRoleSecurityRuleDto implements IInterOrgUnitAccessRoleSecurityRuleDto {
  public accessor_inter_org_unit_access_id : string;

  protected override GetAccessorEntityId(): string
  {
    return this.accessor_inter_org_unit_access_id;
  }

  protected override SetAccessorEntityId(value : string) : void
  {
    this.accessor_inter_org_unit_access_id = value;
  }
}

//#endregion --- Entity-Role Security Rule CLASSES ---

//#region --- Entity-Accessing-Role Security Rule CLASSES ---

// Users accessible to the role

export abstract class UserAccessingRoleSecurityRuleDto extends EntityAccessingRoleSecurityRuleDto implements IUserAccessingRoleSecurityRuleDto {
  public accessor_user_id : string;

  protected override GetAccessorEntityId(): string {
    return this.accessor_user_id;
  }
  protected override SetAccessorEntityId(value: string): void {
    this.accessor_user_id = value;
  }

  protected override GetSecurityRuleAccessedEntityId() : string
  {
    return this.accessor_user_id;
  }
}


// Person-Users accessible to the role
export class PersonUserAccessingRoleSecurityRuleDto extends UserAccessingRoleSecurityRuleDto implements IPersonUserAccessingRoleSecurityRuleDto {
  public accessor_user_email : string;

  protected override GetSecurityRuleName() : string
  {
    return this.accessor_user_email;
  }

  protected override GetSecurityRuleDescription() : string
  {
    return this.accessor_user_email;
  }
}

// Applications accessible to the role
export class ApplicationAccessingRoleSecurityRuleDto extends UserAccessingRoleSecurityRuleDto implements IApplicationAccessingRoleSecurityRuleDto {
  public accessor_application_name : string;

  protected override GetSecurityRuleName() : string
  {
    return this.accessor_application_name;
  }

  protected override GetSecurityRuleDescription() : string
  {
    return this.accessor_application_name;
  }
}

//#endregion --- Entity-Accessing-Role Security Rule CLASSES ---

//#endregion --- Role Security Rule CLASSES ---

//#region --- OrgUnit Security Rule CLASSES ---

//#region --- Base OrgUnit Security Rule CLASSES ---

export abstract class EntityAccessingOrgUnitSecurityRuleDto extends NonInheritableSecurityRuleBaseDto implements IEntityAccessingOrgUnitSecurityRule {
  public accessed_org_unit_id: string;

  public get accessor_entity_id(): string {
    return this.GetAccessorEntityId();
  }
  public set accessor_entity_id(value: string) {
    this.SetAccessorEntityId(value);
  }

  protected override GetSecurityRuleAccessedEntityId() : string {
    return this.accessed_org_unit_id;
  }

  protected abstract GetAccessorEntityId(): string;
  protected abstract SetAccessorEntityId(value: string) : void;
  public abstract SecurityRuleHasOrgUnitType(orgUnitTypeName : string): boolean;
}

export abstract class EntityOrgUnitSecurityRuleDto extends EntityAccessingOrgUnitSecurityRuleDto implements IEntityOrgUnitSecurityRule {
  // Properties used for display-purposes
  public accessed_org_unit_name : string/* = ''*/;
  public accessed_org_unit_org_unit_type_description : string/* = ''*/;
  public accessed_org_unit_types : string[]/* = []*/;

  protected override GetSecurityRuleName() : string
  {
    return this.accessed_org_unit_name;
  }

  protected override GetSecurityRuleDescription() : string
  {
    return this.accessed_org_unit_org_unit_type_description;
  }

  public override SecurityRuleHasOrgUnitType(orgUnitTypeName : string) : boolean
  {
  	return this.AccessedOrgUnitHasOrgUnitType(orgUnitTypeName);
  }

  public AccessedOrgUnitHasOrgUnitType(orgUnitTypeName : string) : boolean
  {
  	return this.accessed_org_unit_types.find(r => r === orgUnitTypeName) !== undefined;
  }
}

//#endregion --- Base OrgUnit Security Rule CLASSES ---


//#region --- Entity-OrgUnit Security Rule CLASSES ---

export class OrgUnitOrgUnitSecurityRuleDto extends EntityOrgUnitSecurityRuleDto implements IOrgUnitOrgUnitSecurityRuleDto {
  public accessor_org_unit_id : string = '';

  protected override GetAccessorEntityId(): string
  {
    return this.accessor_org_unit_id;
  }

  protected override SetAccessorEntityId(value : string) : void
  {
    this.accessor_org_unit_id = value;
  }

}

export class UserOrgUnitSecurityRuleDto extends EntityOrgUnitSecurityRuleDto implements IPersonUserOrgUnitSecurityRuleDto {
  public accessor_user_id : string = '';

  protected override GetAccessorEntityId(): string
  {
    return this.accessor_user_id;
  }

  protected override SetAccessorEntityId(value : string) : void
  {
    this.accessor_user_id = value;
  }

}

//#endregion --- Entity-OrgUnit Security Rule CLASSES ---

//#region --- Entity-Accessing-OrgUnit Security Rule CLASSES ---

export class OrgUnitAccessingOrgUnitSecurityRuleDto extends EntityAccessingOrgUnitSecurityRuleDto implements IOrgUnitAccessingOrgUnitSecurityRuleDto {
  public accessor_org_unit_id : string;

  // Properties used for display-purposes
  public accessor_org_unit_name : string;
  public accessor_org_unit_org_unit_type_description : string;
  public accessor_org_unit_types : string[];

  protected override GetAccessorEntityId(): string {
    return this.accessor_org_unit_id;
  }

  protected override SetAccessorEntityId(value: string): void {
    this.accessor_org_unit_id = value;
  }

  protected override GetSecurityRuleName() : string
  {
    return this.accessor_org_unit_name;
  }

  protected override GetSecurityRuleDescription() : string
  {
    return this.accessor_org_unit_org_unit_type_description;
  }

  protected override GetSecurityRuleAccessedEntityId() : string
  {
    return this.accessor_org_unit_id;
  }

  public override SecurityRuleHasOrgUnitType(orgUnitTypeName : string) : boolean
  {
  	return this.AccessorOrgUnitHasOrgUnitType(orgUnitTypeName);
  }

  // Helper functions
  public AccessorOrgUnitHasOrgUnitType(orgUnitTypeName : string) : boolean
  {
  	return this.accessor_org_unit_types.find(r => r === orgUnitTypeName) !== undefined;
  }
}

//#endregion --- Entity-Accessing-OrgUnit Security Rule CLASSES ---

//#endregion --- OrgUnit Security Rule CLASSES ---

//#endregion --- Security Rule CLASSES ---

//#region --- Maintain Role Data INTERFACES ---

export interface IMaintainRoleDataDto
{
	role: IRoleDto;
	role_action_security_rules: IRoleActionSecurityRuleDto[];
	role_role_security_rules: IRoleRoleSecurityRuleDto[];
}

// Users who can access the role
export interface IMaintainUserRoleDto
{
	user_role_security_rules: IUserRoleSecurityRuleDto[];
}

// Person-Users who can access the role
export interface IMaintainPersonUserRoleDto extends IMaintainUserRoleDto
{
  user: IPersonUserDto;
}

export interface IMaintainApplicationRoleDto extends IMaintainUserRoleDto
{
  user: IApplicationDto;
}

// Users who can access the role
export interface IMaintainUserAccessingRoleDto
{
  role: IRoleDto;
  org_unit: IOrgUnitDto;
}

// Person-Users who can access the role
export interface IMaintainPersonUserAccessingRoleDto extends IMaintainUserAccessingRoleDto
{
	user_accessing_role_security_rules: IPersonUserAccessingRoleSecurityRuleDto[];
}

// Applications who can access the role
export interface IMaintainApplicationAccessingRoleDto extends IMaintainUserAccessingRoleDto
{
	user_accessing_role_security_rules: IApplicationAccessingRoleSecurityRuleDto[];
}

// Roles accessible to the OrgUnitType
export interface IMaintainOrgUnitTypeRoleDto
{
  org_unit_type : IOrgUnitTypeDto;
	org_unit_type_role_security_rules : IOrgUnitTypeRoleSecurityRuleDto[];
}

export interface IMaintainInterOrgUnitAccessRoleDto
{
  inter_org_unit_access_id: string;
  accessor_org_unit: IOrgUnitDto;
  accessed_org_unit: IOrgUnitDto;
  inter_org_unit_access_role_security_rules: IInterOrgUnitAccessRoleSecurityRuleDto[];
}

//#endregion --- Maintain Role Data INTERFACES ---

//#region --- Maintain OrgUnit Data INTERFACES ---

export interface IMaintainInterOrgUnitAccessBaseDto {
  org_unit: IOrgUnitDto;
}

export interface IMaintainOrgUnitOrgUnitDto extends IMaintainInterOrgUnitAccessBaseDto {
  get accessor_org_unit() : IOrgUnitDto;
  set accessor_org_unit(value: IOrgUnitDto);
  org_unit_org_unit_security_rules: IOrgUnitOrgUnitSecurityRuleDto[];
}

export interface IMaintainOrgUnitAccessingOrgUnitDto extends IMaintainInterOrgUnitAccessBaseDto {
  get accessed_org_unit() : IOrgUnitDto;
  set accessed_org_unit(value: IOrgUnitDto);
  org_unit_accessing_org_unit_security_rules: IOrgUnitAccessingOrgUnitSecurityRuleDto[];
}

export interface IMaintainPersonUserOrgUnitDto {
  user: IPersonUserDto;
  user_org_unit_security_rules: IPersonUserOrgUnitSecurityRuleDto[];
}

export interface IMaintainApplicationOrgUnitDto {
  user: IApplicationDto;
  user_org_unit_security_rules: IApplicationOrgUnitSecurityRuleDto[];
}

//#endregion --- Maintain OrgUnit Data INTERFACES ---

//#region --- Maintain Role Data CLASSESs ---

export class MaintainRoleDataDto implements IMaintainRoleDataDto {
  @Type(() => RoleDto)
	public role: IRoleDto;

  @Type(() => RoleActionSecurityRuleDto)
	public role_action_security_rules: IRoleActionSecurityRuleDto[];

  @Type(() => RoleRoleSecurityRuleDto)
	public role_role_security_rules: IRoleRoleSecurityRuleDto[];
}

export class MaintainUserRoleDto implements IMaintainUserRoleDto {
  @Type(() => UserRoleSecurityRuleDto)
	public user_role_security_rules: IUserRoleSecurityRuleDto[];
}

export class MaintainPersonUserRoleDto extends MaintainUserRoleDto implements IMaintainPersonUserRoleDto {
  @Type(() => PersonUserDto)
  public user: IPersonUserDto;
}

export class MaintainApplicationRoleDto extends MaintainUserRoleDto implements IMaintainApplicationRoleDto {
  @Type(() => ApplicationDto)
  public user: ApplicationDto;
}

export class MaintainOrgUnitTypeRoleDto implements IMaintainOrgUnitTypeRoleDto
{
  @Type(() => OrgUnitTypeDto)
  public org_unit_type : IOrgUnitTypeDto;

  @Type(() => OrgUnitTypeRoleSecurityRuleDto)
	public org_unit_type_role_security_rules : IOrgUnitTypeRoleSecurityRuleDto[];

}

export class MaintainInterOrgUnitAccessRoleDto implements IMaintainInterOrgUnitAccessRoleDto
{
  public inter_org_unit_access_id: string;

  @Type(() => OrgUnitDto)
  public accessor_org_unit: IOrgUnitDto;

  @Type(() => OrgUnitDto)
  public accessed_org_unit: IOrgUnitDto;

  @Type(() => InterOrgUnitAccessRoleSecurityRuleDto)
  public inter_org_unit_access_role_security_rules: IInterOrgUnitAccessRoleSecurityRuleDto[];
}

export abstract class MaintainUserAccessingRoleDto implements IMaintainUserAccessingRoleDto {
  @Type(() => RoleDto)
  public role: IRoleDto;

  @Type(() => OrgUnitDto)
  public org_unit: IOrgUnitDto;
}

export class MaintainPersonUserAccessingRoleDto extends MaintainUserAccessingRoleDto implements IMaintainPersonUserAccessingRoleDto {
  @Type(() => PersonUserAccessingRoleSecurityRuleDto)
  public user_accessing_role_security_rules: IPersonUserAccessingRoleSecurityRuleDto[];
}

export class MaintainApplicationAccessingRoleDto extends MaintainUserAccessingRoleDto implements IMaintainApplicationAccessingRoleDto {
  @Type(() => ApplicationAccessingRoleSecurityRuleDto)
  public user_accessing_role_security_rules: IApplicationAccessingRoleSecurityRuleDto[];
}

export abstract class MaintainInterOrgUnitAccessBaseDto implements IMaintainInterOrgUnitAccessBaseDto {
  @Type(() => OrgUnitDto)
  public org_unit: IOrgUnitDto;
}

export class MaintainOrgUnitOrgUnitDto extends MaintainInterOrgUnitAccessBaseDto implements IMaintainOrgUnitOrgUnitDto{
  public get accessor_org_unit(): IOrgUnitDto {
    return this.org_unit;
  }
  public set accessor_org_unit(value: IOrgUnitDto) {
    this.org_unit = value;
  }

  @Type(() => OrgUnitOrgUnitSecurityRuleDto)
  public org_unit_org_unit_security_rules: IOrgUnitOrgUnitSecurityRuleDto[];
}

export class MaintainOrgUnitAccessingOrgUnitDto extends MaintainInterOrgUnitAccessBaseDto implements IMaintainOrgUnitAccessingOrgUnitDto {
  public get accessed_org_unit(): IOrgUnitDto {
    return this.org_unit;
  }
  public set accessed_org_unit(value: IOrgUnitDto) {
    this.org_unit = value
    ;
  }
  public get accessor_org_unit(): IOrgUnitDto {
    return this.org_unit;
  }
  public set accessor_org_unit(value: IOrgUnitDto) {
    this.org_unit = value;
  }

  @Type(() => OrgUnitAccessingOrgUnitSecurityRuleDto)
  public org_unit_accessing_org_unit_security_rules: IOrgUnitAccessingOrgUnitSecurityRuleDto[];

}

export class MaintainOrgUnitInheritsOrgUnitDto extends MaintainInterOrgUnitAccessBaseDto implements IMaintainOrgUnitAccessingOrgUnitDto {
  public get accessed_org_unit(): IOrgUnitDto {
    return this.org_unit;
  }
  public set accessed_org_unit(value: IOrgUnitDto) {
    this.org_unit = value
    ;
  }
  public get accessor_org_unit(): IOrgUnitDto {
    return this.org_unit;
  }
  public set accessor_org_unit(value: IOrgUnitDto) {
    this.org_unit = value;
  }

  @Type(() => OrgUnitAccessingOrgUnitSecurityRuleDto)
  public org_unit_accessing_org_unit_security_rules: IOrgUnitAccessingOrgUnitSecurityRuleDto[];

}

export class MaintainPersonUserOrgUnitDto implements IMaintainPersonUserOrgUnitDto {
  @Type(() => PersonUserDto)
  public user: IPersonUserDto;

  @Type(() => UserOrgUnitSecurityRuleDto)
  public user_org_unit_security_rules: IPersonUserOrgUnitSecurityRuleDto[];
}

export class MaintainApplicationOrgUnitDto implements IMaintainApplicationOrgUnitDto {
  @Type(() => ApplicationDto)
  public user: IApplicationDto;

  @Type(() => UserOrgUnitSecurityRuleDto)
  public user_org_unit_security_rules: IApplicationOrgUnitSecurityRuleDto[];
}

//#endregion --- Maintain Role Data CLASSESs ---

//#region --- Summary Action INTERFACE ---

// Interface for SummaryRoleActionRequestDto
export interface ISummaryRoleActionRequestDto {
  role_id : string;
  role_action_list : IRoleActionSecurityRuleDto[];
  role_role_list : IRoleRoleSecurityRuleDto[];
}

// Interface for SummaryUserActionRequestDto
export interface ISummaryUserActionRequestDto {
  user_id : string;
  user_role_list : IUserRoleSecurityRuleDto[];
}

// Interface for SummaryOrgUnitTypeActionRequestDto
export interface ISummaryOrgUnitTypeActionRequestDto {
  org_unit_type_id : string;
  org_unit_type_role_list : IOrgUnitTypeRoleSecurityRuleDto[];
}

// Interface for SummaryInterOrgUnitAccessActionRequestDto
export interface ISummaryInterOrgUnitAccessActionRequestDto {
  inter_org_unit_access_id : string;
  inter_org_unit_access_role_list : IInterOrgUnitAccessRoleSecurityRuleDto[];
}

// Interface for SummaryActionResponseDto
export interface ISummaryActionResponseDto {
  summary_role_actions : IRoleActionInfoDto[];
}

//#endregion --- Summary Action INTERFACE ---

//#region --- Summary Action CLASSES ---

export class SummaryRoleActionRequestDto implements ISummaryRoleActionRequestDto {
  public role_id: string;

  @Type(() => RoleActionSecurityRuleDto)
  public role_action_list: IRoleActionSecurityRuleDto[];

  @Type(() => RoleRoleSecurityRuleDto)
  public role_role_list: IRoleRoleSecurityRuleDto[];
}

export class SummaryUserActionRequestDto implements ISummaryUserActionRequestDto {
  public user_id : string;

  @Type(() => UserRoleSecurityRuleDto)
  public user_role_list: IUserRoleSecurityRuleDto[];
}

export class SummaryOrgUnitTypeActionRequestDto implements ISummaryOrgUnitTypeActionRequestDto {
  public org_unit_type_id : string;

  @Type(() => OrgUnitTypeRoleSecurityRuleDto)
  public org_unit_type_role_list : IOrgUnitTypeRoleSecurityRuleDto[];
}

// Interface for SummaryInterOrgUnitAccessActionRequestDto
export class SummaryInterOrgUnitAccessActionRequestDto implements ISummaryInterOrgUnitAccessActionRequestDto {
  public inter_org_unit_access_id : string;

  @Type(() => InterOrgUnitAccessRoleSecurityRuleDto)
  public inter_org_unit_access_role_list : IInterOrgUnitAccessRoleSecurityRuleDto[];
}

export class SummaryActionResponseDto implements ISummaryActionResponseDto {
  public summary_role_actions: IRoleActionInfoDto[];
}

//#endregion --- Summary Action CLASSES ---

