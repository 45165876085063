export const ConfirmSaveMessage = 'Are you sure you want to save the changes made? '

export enum KnoxApiUrls {
	Actions = "actions",
	InterOrgUnitAccesses = "inter-org-unit-accesses",
	OrgUnitInheritOrgUnits = "org-unit-access-org-units",
	OrgUnits = "org-units",
	OrgUnitTypeRoles = "org-unit-type-roles",
	OrgUnitTypes = "org-unit-types",
	OrgUnitUsers = "org-unit-users",
	Roles = "roles",
	SystemRoles = "system-roles",
  SystemActions = "system-actions",
	Users = "users",
	UserRoles = "UserRoles"  
}

export enum KnoxAuthApiUrls {
	Auth = "auth",
	ForgotPassword = "forgot-password",
	ResetPassword = "reset-password",
	ChangePassword = "change-password",
	IdentityProvider = "identity-provider",
	Login = "login",
	MultiFactorSecret = "multi-factor/secret",
	TwoFactor = "two-factor",
}
