import {
	IRoleDto,
	KnoxApiService,
} from '@aex/security/shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { SECURITY_ROUTE_PARAMS } from "../helpers/security-route-parameters";

@Injectable()
export class GetRoleResolver implements Resolve<IRoleDto> {
	constructor(private readonly knoxApiService: KnoxApiService) { }

	public resolve(route: ActivatedRouteSnapshot): Observable<IRoleDto> {
		const roleId = route.params[SECURITY_ROUTE_PARAMS.roleId];

		return this.knoxApiService.getRole(roleId);
	}
}

