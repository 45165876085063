import {
  IPersonUserDto,
	KnoxApiService,
	SECURITY_ROUTE_PARAMS,
	SecurityCompanyService,
} from "@aex/security/shared";
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
} from '@angular/router';
import {Observable} from 'rxjs';
import {ICompanyPersonDataDto, KEY_USER_ID} from "@aex/shared/common-lib";
import {map, switchMap} from "rxjs/operators";
import {PersonService} from "@aex/shared/apis";
import {BaseConfigService} from "@aex/shared/root-services";

@Injectable()
export class GetUsersResolver implements Resolve<IPersonUserDto[]> {
	constructor(
			private readonly knoxApiService: KnoxApiService,
			private readonly companyService: SecurityCompanyService,
	) {
	}
	public resolve(): Observable<IPersonUserDto[]> {
		const companyId = this.companyService.getCompanyId();

		return this.knoxApiService.getOrgUnitUsers(companyId);
	}
}


@Injectable()
export class GetPersonUserResolver implements Resolve<IPersonUserDto> {

	constructor(private readonly knoxApiService: KnoxApiService) { }

	public resolve(route: ActivatedRouteSnapshot): Observable<IPersonUserDto> {
		const userId = route.params[SECURITY_ROUTE_PARAMS.userId];

		return this.knoxApiService.getPersonUser(userId);
	}
}

@Injectable()
export class GetCurrentUserResolver implements Resolve<IPersonUserDto> {

	constructor(private readonly configService: BaseConfigService, private readonly knoxApiService: KnoxApiService) { }

	public resolve(): Observable<IPersonUserDto> {
		const userId = this.configService.retrieve(KEY_USER_ID);

		return this.knoxApiService.getPersonUser(userId);
	}
}



@Injectable()
export class GetCompanyPersonDataResolver implements Resolve<[IPersonUserDto, ICompanyPersonDataDto]> {

	constructor(
			private readonly companyService: SecurityCompanyService,
			private readonly knoxApiService: KnoxApiService,
			private readonly personService: PersonService,
	) { }

	private includeCompanyPersonData(personUserDto: IPersonUserDto): Observable<[IPersonUserDto, ICompanyPersonDataDto]>
	{
		return this.personService.getCompanyPersonData(
				this.companyService.getCompanyDto().external_org_unit_id,
				personUserDto.person_id,
		).pipe(
				map(
						(companyPersonData: ICompanyPersonDataDto) : [IPersonUserDto, ICompanyPersonDataDto] => {
							return [personUserDto, companyPersonData];
						},
				),
		);
	}

	public resolve(route: ActivatedRouteSnapshot): Observable<[IPersonUserDto, ICompanyPersonDataDto]> {
		const userId = route.params[SECURITY_ROUTE_PARAMS.userId];

		return this.knoxApiService.getPersonUser(userId).pipe(
				switchMap(
						(personUserDto: IPersonUserDto) : Observable<[IPersonUserDto, ICompanyPersonDataDto]> =>{
							return this.includeCompanyPersonData(personUserDto);
						},
				),
		);
	}
}
