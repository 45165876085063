import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
	IMaintainOrgUnitTypeRoleDto,
	KnoxApiService,
} from "@aex/security/shared";

@Injectable({
	providedIn: 'root',
})
export class CompanyTypeRoleService {

	constructor(
			private readonly knoxApiService: KnoxApiService,
	) {
	}

	public getManageCompanyTypeRoles(orgUnitTypeId: string): Observable<IMaintainOrgUnitTypeRoleDto> {
		return this.knoxApiService.getCompanyTypeDataRoleData(orgUnitTypeId);
	}

}
