import { ErrorInterceptor } from '@aex/shared/common-lib';
import { AuthService, HttpErrorService } from '@aex/shared/root-services';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SECURITY_ROUTES } from '../helpers/security-routing';


@Injectable()
export class SecurityErrorInterceptor extends ErrorInterceptor {

	constructor(
		protected override readonly authService: AuthService,
		protected override readonly router: Router,
		protected override readonly httpError: HttpErrorService,
	) {
		super(authService, router, httpError);
	}

	protected override handleForbidden(): void {
		this.authService.logout().subscribe();
		this.router.navigateByUrl(SECURITY_ROUTES.login.path).then();
	}
}