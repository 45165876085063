import { Routes } from '@angular/router';
import { AuthContainerComponent } from './main/auth-container/auth-container.component';
import { HomeContainerComponent } from './main/home-container/home-container.component';
import { CanDeactivateGuard } from '@aex/ngx-toolbox';
import { AuthGuard } from '@aex/shared/common-lib';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { GetUserOrgUnitsResolver } from '@aex/security/shared';

export const NEXUS_BASE_ROUTES: Routes = [
	{
		path: '',
		redirectTo: '/home', // Default Page
		pathMatch: 'full',
	},
	{
		path: '',
		component: AuthContainerComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('@aex/security/authentication').then(
						(m) => m.SecurityAuthenticationModule,
					),
			},
		],
	},
	{
		path: '',
		component: HomeContainerComponent,
		canDeactivate: [CanDeactivateGuard],
		canActivate: [AuthGuard],
		children: [
			{
				path: 'home',
				component: DashboardComponent,
				resolve: {
					companies: GetUserOrgUnitsResolver,
				},
			},
			{
				path: 'security',
				loadChildren: () =>
					import('@aex/security/system').then((m) => m.SecuritySystemModule),
			},
		],
	},
];
