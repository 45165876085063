import { mapToNull, namespace, WithDestroy } from '@aex/ngx-toolbox';
import { IFullService, IProduct } from '@aex/shared/common-lib';
import { Injectable } from '@angular/core';
import { DBSchema, IDBPDatabase, IDBPObjectStore, IDBPTransaction, openDB, StoreKey, StoreValue } from 'idb';
import { IndexNames, StoreNames } from 'idb/build/esm/entry';
import { forkJoin, from, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

const DATABASE_NAME = namespace('client-interface');

export const STORE_SERVICE = 'service';
export const STORE_PRODUCT = 'product';
export const STORE_SERVICE_CUSTOMER_INDEX = 'idx-service-customer_id';
const DB_VERSION = 1;
export const MAX_PERCENT_BEFORE_TRUNCATE = .2;

@Injectable()
export class DbService extends WithDestroy() {

	constructor() {
		super();

		if (!('indexedDB' in window))
			throw new Error('This browser doesn\'t support IndexedDB');

	}

	private db: IDBPDatabase<IMainDbSchema>;

	public initDb(): Observable<IDBPDatabase<IMainDbSchema>> {
		return this.db
				? of(this.db)
				: from(openDB<IMainDbSchema>(DATABASE_NAME, DB_VERSION, {
					upgrade: (db) => {
						// @ts-expect-error: Argument of type 'string' is not assignable to parameter of type 'never'
						const store = db.createObjectStore(STORE_SERVICE, {keyPath: 'service.id'});
						store.createIndex(STORE_SERVICE_CUSTOMER_INDEX, 'service.customer_id');
						// @ts-expect-error: Argument of type 'string' is not assignable to parameter of type 'never'
						db.createObjectStore(STORE_PRODUCT, {keyPath: 'id'});
					},
				})).pipe(tap(db => this.db = db));
	}

	private tx<StoreName extends StoreNames<IMainDbSchema>>(
			storeName: StoreName,
			mode: IDBTransactionMode,
	): IDBPTransaction<IMainDbSchema, [StoreName]> {
		return this.db.transaction(storeName, mode);
	}

	private storeTx(storeName: StoreNames<IMainDbSchema>, mode: IDBTransactionMode): IDBPObjectStore<IMainDbSchema> {
		return this.tx(storeName, mode).store;
	}

	public insert<StoreName extends StoreNames<IMainDbSchema>>(
			storeName: StoreName,
			item: StoreValue<IMainDbSchema, StoreName>,
	): Observable<StoreKey<IMainDbSchema, StoreName>> {
		return from(this.db.add(storeName, item));
	}

	public select<StoreName extends StoreNames<IMainDbSchema>>(
			storeName: StoreName,
			id: string,
	): Observable<StoreValue<IMainDbSchema, StoreName>> {
		return from(this.db.get(storeName, id));
	}

	public selectAll<StoreName extends StoreNames<IMainDbSchema>>(
			storeName: StoreName,
	): Observable<StoreValue<IMainDbSchema, StoreName>[]> {
		return from(this.db.getAll(storeName));
	}

	private async iterateIndex<StoreName extends StoreNames<IMainDbSchema>, IndexName extends IndexNames<IMainDbSchema, StoreName>>(
			storeName: StoreName,
			indexName: IndexName,
			value: string,
	): Promise<StoreValue<IMainDbSchema, StoreName>[]> {
		// Because I have no idea how to fix this type issue (yet)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const index = this.storeTx(storeName, 'readonly').index(indexName);

		const result = [];

		let cursor = await index.openCursor(value);
		while (cursor) {
			result.push(cursor.value);
			try {
				cursor = await cursor.continue(value);
			} catch (error) {
				cursor = null;
			}
		}

		return result;
	}

	public getAllForIndex<StoreName extends StoreNames<IMainDbSchema>, IndexName extends IndexNames<IMainDbSchema, StoreName>>(
			storeName: StoreName,
			indexName: IndexName,
			value: string,
	): Observable<StoreValue<IMainDbSchema, StoreName>[]> {
		return from(this.iterateIndex(storeName, indexName, value));
	}

	public update<StoreName extends StoreNames<IMainDbSchema>>(
			storeName: StoreName,
			item: StoreValue<IMainDbSchema, StoreName>,
	): Observable<StoreKey<IMainDbSchema, StoreName>> {
		return from(this.db.put(storeName, item));
	}

	public delete(storeName: StoreNames<IMainDbSchema>, id: string): Observable<void> {
		return from(this.db.delete(storeName, id));
	}

	/**
	 * Clears the store and replaces with new items
	 * @param storeName - name of the DB store
	 * @param items - list of new items
	 */
	public replaceAll<StoreName extends StoreNames<IMainDbSchema>>(
			storeName: StoreName,
			items: StoreValue<IMainDbSchema, StoreName>[],
	): Observable<StoreKey<IMainDbSchema, StoreName>[]> {
		const store = this.storeTx(storeName, 'readwrite');
		return from(store.clear()).pipe(
				switchMap(() => {
					const inserts: Observable<StoreKey<IMainDbSchema, StoreName>>[] = items.map(item => from(store.add(item)));
					return forkJoin(inserts);
				}),
		);
	}

	/**
	 * Updates the list of given updates, and deletes the list of to be deleted
	 * @param storeName - store to be manipulated
	 * @param updateItems - update all these
	 * @param deleteIds - delete all these
	 */
	public updateAndDelete<StoreName extends StoreNames<IMainDbSchema>>(
			storeName: StoreName,
			updateItems: StoreValue<IMainDbSchema, StoreName>[],
			deleteIds: string[],
	): Observable<void> {
		const store = this.storeTx(storeName, 'readwrite');
		const deleted = deleteIds.map(id => from(store.delete(id)));
		const updated = updateItems.map(item => from(store.put(item)).pipe(mapToNull()));
		return forkJoin(deleted.concat(updated)).pipe(mapToNull());
	}

	public truncate<StoreName extends StoreNames<IMainDbSchema>>(storeName: StoreName): Observable<void> {
		const store = this.storeTx(storeName, 'readwrite');
		return from(store.clear());
	}

}

interface IMainDbSchema extends DBSchema {
	[STORE_SERVICE]: {
		key: string;
		value: IFullService,
		indexes: { [STORE_SERVICE_CUSTOMER_INDEX]: string },
	}
	[STORE_PRODUCT]: {
		key: string;
		value: IProduct,
	}
}

