import { IYesNoDialogOptions } from '@aex/ngx-toolbox/lib/toolbox/yes-no.modal';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-security-yes-no-dialog',
	template: `
		<h1 mat-dialog-title>
			{{ dialogTitle }}
		</h1>
		<div mat-dialog-content>
			<p>
				{{ dialogMessage }}
			</p>
		</div>
		<div mat-dialog-actions align="center">
			<button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial
				[color]="yesIsGood ? 'primary' : 'warn'"
				[ngClass]="{'admin-nexus-button primary': yesIsGood}">
				Yes
			</button>
			<button mat-raised-button [mat-dialog-close]="false">No</button>
		</div>
	`,
})
export class YesNoDialogComponent {
	public get dialogTitle(): string {
		return this.dialogData.title;
	}

	public get dialogMessage(): string {
		return this.dialogData.message;
	}

	public get yesIsGood(): boolean {
		return this.dialogData.yesIsGood;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: IYesNoDialogOptions,
	) { }
}
