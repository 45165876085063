import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { KnoxApiBaseService } from "./knox-api-base.service";
import { 
  IMayPerformActionRequestList, 
  IMayPerformActionResponseList 
} from "../interfaces/knox-security";

@Injectable({
	providedIn: 'root',
})
export class KnoxSecurityApiService extends KnoxApiBaseService {

	constructor(http: HttpClient) { 
    super(http);
  }

  // Call Knox API to check if user may perform actions
  public userMayPerformActions(permissionRequestList : IMayPerformActionRequestList): Observable<IMayPerformActionResponseList> {
    return this.post<IMayPerformActionRequestList, IMayPerformActionResponseList>("/security/user-perform-action-list", permissionRequestList);
  }

}