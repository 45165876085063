import { BaseConfigService } from '@aex/shared/root-services';
import { Component } from '@angular/core';
import { isNil } from 'lodash';

@Component({
	selector: 'app-auth-container',
	templateUrl: './auth-container.component.html',
	styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent {
	title = 'admin-nexus';

	public get email(): string {
		return this.configService?.configValues?.email ?? `sales@${this.configService.operatorPrettyName.toLowerCase()}.co.za`;
	}

	public get supportNumber(): string {
		return this.configService.configValues?.supportNumber;
	}

	public get showSupportNumber(): boolean {
		return !isNil(this.supportNumber);
	}

	constructor(private readonly configService: BaseConfigService) { }
}
