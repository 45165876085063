import { WithDestroy } from '@aex/ngx-toolbox';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@aex/shared/root-services';

@Injectable()
export class SecurityAuthInterceptor extends WithDestroy() implements HttpInterceptor {
	
	constructor(private readonly authService: AuthService) {
		super();
	}
	
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const setHeaders: { [name: string]: string } = { };

		if (!this.token)
			return next.handle(req.clone({ setHeaders, reportProgress: true }));
		else {
			const newRequest = req.clone({
				setHeaders: {
					Authorization: `Bearer ${this.token}`,
				},
			});
			return next.handle(newRequest);
		}
	}

	public get token(): string {
		return this.authService.authToken;
	}
}
