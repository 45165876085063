import {IEnvironmentBase, ILiveMockConfig} from '@aex/ngx-toolbox';
import {ENVIRONMENT_CONFIG_TOKEN, IEnvironment} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Directive, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Directive()
export abstract class BaseAppConfigLoaderService {

	constructor(
			protected readonly http: HttpClient,
			@Inject(ENVIRONMENT_CONFIG_TOKEN) protected readonly environmentBase: IEnvironmentBase,
	) { }

	protected abstract initLoadAppConfig(): void;
	protected abstract loadServerUrls(): void;
	public abstract loadAppConfig(): Observable<void>;
	public abstract getLiveMockServiceConfig(): ILiveMockConfig;
}

@Directive()
export abstract class AppConfigLoaderService extends BaseAppConfigLoaderService {

	protected constructor(
		http: HttpClient,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environmentBase: IEnvironmentBase,
	) {
		super(http, environmentBase);
	}

	public get environment(): IEnvironment {
		return this.environmentBase as IEnvironment;
	}
}
