import {companyManagementServiceUrl} from "@aex/shared/common-lib";

export enum CompanyManagementServiceUrls {
	Companies = 'companies',
	Persons= 'persons',
  CompanyPersons = 'company-persons',
  Employees = 'employees',
}

export class CompanyManagementServiceApi {

	public static Persons(): string {
		return companyManagementServiceUrl(CompanyManagementServiceUrls.Persons);
	}
	public static CompanyPersons(): string {
		return companyManagementServiceUrl(CompanyManagementServiceUrls.CompanyPersons);
	}
	public static CompanyPersonsEmployees(): string {
		return companyManagementServiceUrl(`${CompanyManagementServiceUrls.CompanyPersons}/${CompanyManagementServiceUrls.Employees}`);
	}

	public static CompanyPersonDataWithIds(companyId: string, personId: string): string {
		return companyManagementServiceUrl(`${CompanyManagementServiceUrls.Companies}/${companyId}/${CompanyManagementServiceUrls.Persons}/${personId}`);
	}

	public static CompanyPersonData(): string {
		return companyManagementServiceUrl(`${CompanyManagementServiceUrls.CompanyPersons}/data`);
	}

}
