import { appRoute, IAppRoute, isNonNull, pathify, Primitive } from '@aex/ngx-toolbox';
import { HttpStatusCode } from '@angular/common/http';
import { AuthType, ProductPriceType } from './enums';
import moment from 'moment';
import { addHours, endOfDay, format, isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import { IDateRange, IServerConfig } from './interfaces';
import { ITokenResult, IRouteConfig } from './global-config';
import { IProduct } from './product';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IParamMetaData } from './param-meta-data';

export const SERVER_CONFIG: IServerConfig = {};
export const ROUTE_CONFIG: IRouteConfig = {};


function basePortal(): Primitive[] {
	return [SERVER_CONFIG.portalUrl].filter(isNonNull);
}

function baseSearchUrlArray(): Primitive[] {
	return [SERVER_CONFIG.searchServerUrl].filter(isNonNull);
}

function baseUrlArray(): Primitive[] {
	return [SERVER_CONFIG.serverUrl].filter(isNonNull);
}
function knoxbaseUrlArray(): Primitive[] {
	return [SERVER_CONFIG.knoxUrl].filter(isNonNull);
}

function baseCompanyManagementServiceUrlArray():Primitive[]{
	return [SERVER_CONFIG.companyManagementServiceUrl].filter(isNonNull);
}
function baseSupportUrlArray(): Primitive[] {
	return [SERVER_CONFIG.supportUrl].filter(isNonNull);
}

function basePaymentServerUrlArray(): Primitive[] {
	return [SERVER_CONFIG.paymentServerUrl].filter(isNonNull);
}

function basePurchaseOrderServerUrlArray(): Primitive[] {
	return [SERVER_CONFIG.purchaseOrderServerUrl].filter(isNonNull);
}

function baseNotificationServiceUrlArray(): Primitive[] {
	return [SERVER_CONFIG.notificationServiceUrl].filter(isNonNull);
}

function baseFusionSolarServerUrlArray(): Primitive[] {
	return [SERVER_CONFIG.fusionSolarServiceUrl].filter(isNonNull);
}

function baseSecurityServiceUrlArray(): Primitive[] {
	return [SERVER_CONFIG.securityServiceUrl].filter(isNonNull);
}

function baseWeatherServerUrlArray(): Primitive[] {
	return [SERVER_CONFIG.weatherServerUrl].filter(isNonNull);
}

function baseLoadSheddingServerUrlArray(): Primitive[] {
	return [SERVER_CONFIG.loadSheddingServerUrl].filter(isNonNull);
}

function basePushNotificationsServerUrlArray(): Primitive[] {
	return [SERVER_CONFIG.pushNotificationsServerUrl].filter(isNonNull);
}

function baseWifiServerUrlArray(): Primitive[] {
	return [SERVER_CONFIG.wifiServerUrl].filter(isNonNull);
}

export function portalServer(...parts: Primitive[]): string {
	return pathify(...basePortal().concat(parts));
}

export function serverUrl(...parts: Primitive[]): string {
	return pathify(...baseUrlArray().concat(parts));
}

export function knoxUrl(...parts: Primitive[]): string {
	return pathify(...knoxbaseUrlArray().concat(parts));
}

export function companyManagementServiceUrl(...parts: Primitive[]): string {
	return pathify(...baseCompanyManagementServiceUrlArray().concat(parts));
}


export function searchUrl(...parts: Primitive[]): string {
	return pathify(...baseSearchUrlArray().concat(parts.filter(Boolean)));
}

export function securityServiceUrl(...parts: Primitive[]): string {
	return pathify(...baseSecurityServiceUrlArray().concat(parts));
}

export function supportUrl(...parts: Primitive[]): string {
	return pathify(...baseSupportUrlArray().concat(parts));
}

export function paymentServiceServer(...parts: Primitive[]): string {
	return pathify(...basePaymentServerUrlArray().concat(parts));
}

export function purchaseServiceServer(...parts: Primitive[]): string {
	return pathify(...basePurchaseOrderServerUrlArray().concat(parts));
}

export function notificationServiceServer(...parts: Primitive[]): string {
	return pathify(...baseNotificationServiceUrlArray().concat(parts));
}

export function fusionSolarServer(...parts: Primitive[]): string {
	return pathify(...baseFusionSolarServerUrlArray().concat(parts));
}

export function weatherServer(...parts: Primitive[]): string {
	return pathify(...baseWeatherServerUrlArray().concat(parts));
}

export function loadSheddingServer(...parts: Primitive[]): string {
	return pathify(...baseLoadSheddingServerUrlArray().concat(parts));
}

export function pushNotificationsServer(...parts: Primitive[]): string {
	return pathify(...basePushNotificationsServerUrlArray().concat(parts));
}

export function wifiServer(...parts: Primitive[]): string {
	return pathify(...baseWifiServerUrlArray().concat(parts));
}

export function maybeSurl(includeServer: boolean, ...parts: Primitive[]): string {
	return includeServer ? serverUrl(...parts): pathify(...parts);
}

function getActiveStatuses(): Primitive[] {
	const ACTIVE = 2;
	const EXPIRING = 7;
	const CANCELLATION_IN_PROGRESS = 9;
	return [ACTIVE, EXPIRING, CANCELLATION_IN_PROGRESS];
}

export function getLanding(): IAppRoute {
	return appRoute(ROUTE_CONFIG.startUrl);
}

export const ACTIVE_STATUSES = getActiveStatuses();

export class FilenameUtils {

	public static getExt(filename: string): string {
		return filename.split('.').pop();
	}

	public static getTimestampFilename(filename: string): string {
		return `${Math.floor(Date.now() / 1000).toString()}.${FilenameUtils.getExt(filename)}`;
	}
}

export function imageTypeToNamespace(type: string): string {
	return type?.replace(' ', '_');
}

export function isGuid(value: string): boolean {
	const regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
	const match = regex.exec(value);
	return match !== null;
}

export function resolveProductPrice(product: IProduct): IProduct {
	const roundNumber = 100;
	product.pricing.forEach((price) => {
		price.value_incl = Math.round(price.value_incl * roundNumber) / roundNumber;
		price.value = Math.round(price.value * roundNumber) / roundNumber;
		if (price.price_type === ProductPriceType.MONTHLY)
			product.monthly_price = price;
		else if (price.price_type === ProductPriceType.DAY_TO_DAY)
			product.daily_price = price;
		else if (price.price_type === ProductPriceType.ONCE_OFF)
			product.once_off_price = price;
	});
	return product;
}

export const validMediumStatus = 1;
export const invalidMediumStatus = 2;
export const pendingMediumStatus = 3;

export const stripeProviderId = '6e234008-f9ef-44b5-9480-81b4005fdfcb';

export function getHTTPStatusText(httpStatusCode: HttpStatusCode) {
	switch (httpStatusCode) {
		case HttpStatusCode.Unauthorized:
			return 'Unauthorized';
		case HttpStatusCode.Forbidden:
			return 'Forbidden';
		case HttpStatusCode.InternalServerError:
			return 'Internal Server Error';
		case HttpStatusCode.GatewayTimeout:
			return 'Gateway Timeout';
		case HttpStatusCode.NotFound:
			return 'Not Found';
		case HttpStatusCode.BadRequest:
			return 'Bad Request';
		case HttpStatusCode.Conflict:
			return 'Conflict';
		case HttpStatusCode.RequestTimeout:
			return 'Request Timeout';
		default:
			return 'HttpError';
	}
}

// export const USER_ROLES = {
// 	salesagent: 'role:salesagent',
// 	fno: 'role:fno',
// 	isp: 'role:isp',
// 	accountmanager: 'role:accountmanager',
// 	admin: 'role:admin',
// 	aex: 'role:aex',
// 	areaadmin: 'role:areaadmin',
// 	areaview: 'role:areaview',
// 	biExco: 'role:bi_exco',
// 	biFinance: 'role:bi_finance',
// 	biOperational: 'role:bi_operational',
// 	biReports: 'role:bi_reports',
// 	biSales: 'role:bi_sales',
// 	configOperator: 'role:config operator',
// 	fnoValidator: 'role:fno_validator',
// 	fnoAdmin: 'role:fnoadmin',
// 	frReportingRole: 'role:frreportingrole',
// 	installationManagement: 'role:installationmanagement',
// 	installer: 'role:installer',
// 	ispInstaller: 'role:isp_installer',
// 	ispOrderValidation: 'role:isp_order_validation',
// 	ispAdmin: 'role:ispadmin',
// 	monitoring: 'role:monitoring',
// 	noc: 'role:noc',
// 	nocAdmin: 'role:nocadmin',
// 	provisioning: 'role:provisioning',
// 	public: 'role:public',
// 	servicePortAdmin: 'role:serviceportadmin',
// 	specialAdmin: 'role:specialadmin',
// 	paymentCapturer: 'role:paymentcapturer',
// 	paymentAdmin: 'role:paymentadmin',
// 	paymentViewer: 'role:paymentviewer',
//  refundCapturer: 'role:refundcapturer',
// };

export class Utils {
	private static readonly dateFormat = 'yyyy-MM-dd';
	private static readonly timeFormat = 'HH:mm:ss';
	private static readonly defaultTime = '08:00:00';
	private static readonly MAX_DATE_SIZE = 10;
	private static readonly MAX_TIME_SIZE = 8;

	public static getStartTime(date: string, time: string): Date {
		if (Utils.isEmpty(date))
			return null;

		const _time = Utils.isEmpty(time) ? Utils.defaultTime: time;

		if (date.length > Utils.MAX_DATE_SIZE)
			Utils.formatDate(date);

		if (time.length > Utils.MAX_TIME_SIZE)
			Utils.formatTime(time);

		return new Date(`${date} ${_time}`);
	}

	public static getEndTime(dtime: Date, duration: number = 2): Date {
		if (Utils.isEmpty(dtime))
			return null;
		return addHours(dtime, duration);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static isEmpty(value: any): boolean {
		return value === null || value === undefined || value === '';
	}

	public static formatDate(date: string): string {
		if (Utils.isEmpty(date))
			return null;
		return format(new Date(date), Utils.dateFormat);
	}

	public static formatTime(time: string): string {
		if (Utils.isEmpty(time))
			return null;
		return format(new Date(time), Utils.timeFormat);
	}

	public static parameter(name: string): string {
		const url = window.location.href;
		const REGEX_ARRAY_SIZE = 2;
		const _name = name.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp(`[?&]${ _name}(=([^&#]*)|&|#|$)`);
		const results = regex.exec(url);
		if (!results || !results[REGEX_ARRAY_SIZE])
			return '';

		return decodeURIComponent(results[REGEX_ARRAY_SIZE].replace(/\+/g, ' '));
	}

	public static dateDiff(start: string, end: string): number {
		const startDate = moment(start);
		const endDate = moment(end);
		return startDate.diff(endDate, 'days');
	}

	public static bearer(token: string): string {
		return `Bearer ${token}`;
	}

	public static checkIfUserIs(userRole: string, tokenResult: ITokenResult): boolean {
		return tokenResult.claims.find((role: string) => role === userRole)
				=== userRole;
	}

	public static authTypeFromMeta(meta: IParamMetaData): AuthType {
		return (meta ? meta.authToken || AuthType.USER : AuthType.USER) as AuthType;
	}
}

export function isDateInRange(date: Date, range: IDateRange, options?: IDateCompareOptions): boolean {
	const lStartDate = options?.useDayEdge && range.startDate ? startOfDay(range.startDate): range.startDate;
	const lEndDate = options?.useDayEdge && range.endDate ? endOfDay(range.endDate): range.endDate;
	return (!lStartDate || isAfter(date, lStartDate) || isEqual(date, lStartDate))
			&& (!lEndDate || isBefore(date, lEndDate) || isEqual(date, lEndDate));
}

export interface IDateCompareOptions {
	useDayEdge: boolean;
}

export function coalesceRouteParam(route: ActivatedRouteSnapshot, name: string): string {
	return route.params[name] ?? route.queryParams[name];
}
