import {
	IMaintainOrgUnitAccessingOrgUnitDto,
	IMaintainOrgUnitOrgUnitDto, KnoxApiService,
} from '@aex/security/shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SECURITY_ROUTE_PARAMS } from "../helpers/security-route-parameters";

@Injectable()
export class GetManageOrgUnitOrgUnitResolver implements Resolve<IMaintainOrgUnitOrgUnitDto> {
	constructor( private readonly knoxApiService: KnoxApiService) {
	}
	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainOrgUnitOrgUnitDto> {
		const orgUnitId:string  = route.params[SECURITY_ROUTE_PARAMS.orgUnitId]
		return this.knoxApiService.getOrgUnitsAccessedByOrgUnit(orgUnitId);
	}
}

@Injectable()
export class GetManageOrgUnitAccessingOrgUnitResolver implements Resolve<IMaintainOrgUnitAccessingOrgUnitDto> {
	constructor( private readonly knoxApiService: KnoxApiService) {
	}
	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainOrgUnitAccessingOrgUnitDto> {
		const orgUnitId:string  = route.params[SECURITY_ROUTE_PARAMS.orgUnitId]
		return this.knoxApiService.getOrgUnitsAccessingOrgUnit(orgUnitId);
	}
}

@Injectable()
export class GetManageOrgUnitAccessOrgUnitResolver implements Resolve<IMaintainOrgUnitOrgUnitDto> {
	constructor( private readonly knoxApiService: KnoxApiService) {
	}
	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainOrgUnitOrgUnitDto> {
		const orgUnitId:string  = route.params[SECURITY_ROUTE_PARAMS.orgUnitId]
		return this.knoxApiService.getMaintainInheritedOrgUnits(orgUnitId);
	}
}
