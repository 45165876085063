import {IActionDto, IBoolResponse, ISystemActions, KnoxApiUrls} from "@aex/security/shared";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {KnoxApi} from "@aex/shared/apis";
import {ParamMetaData} from "@aex/shared/common-lib";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn:'root'})
export class RegisterKnoxActionsService {
  constructor(
    private readonly http: HttpClient,
  ) {}

  public registerKnoxActions(systemName : string, actions : IActionDto[]): Observable<IBoolResponse>  {
    // Any actions to register?
    if (actions.length === 0)
      // Return true
      return new Observable<IBoolResponse>(observer => {
        observer.next({
          success: true,
        });
        observer.complete();
      });


    const systemActions : ISystemActions = {
      system_name: systemName,
      actions : actions,
    };

	  return this.http.post<IBoolResponse>(
			  KnoxApi.knoxUrlGeneral(`${KnoxApiUrls.SystemActions}/register-client-actions`),
			  systemActions,
			  { params: new ParamMetaData({ handleError: 'knox'}) },
	  );
    // return this.knoxApiService.registerKnoxActions(systemActions);

  }
}
