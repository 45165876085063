import { appRoute } from '@aex/ngx-toolbox';

export const APP_ROUTES = {
	login: appRoute('login'),
	landing: appRoute('landing'),
	checkout: appRoute('checkout'),
	register: appRoute('register'),
	doorhanger: appRoute('smi'),
	registerContractor: appRoute('register-contractor'),
	coverage: appRoute('coverage'),
	dashboard: appRoute('dashboard'),
	// portal: appRoute('portal'),
	products: appRoute('products'),
	premise: appRoute('premise'),
	schedule: appRoute('schedule'),
	quote: appRoute('quote'),
	showInterest: appRoute('show-interest'),
	device: appRoute(':type/:serialNumber'),
	serial: appRoute(':type/:serialNumber'),
	forgot: appRoute('forgot'),
	shop: appRoute('shop'),
	support: appRoute('support'),
	firstPurchase: appRoute('first-purchase'),
	serviceAccount: appRoute('service-account'),
	wifiDetails: appRoute('wifi-details'),
	cardDetails: appRoute('card-details'),
	debitDays: appRoute('debit-days'),
	paymentMediums: appRoute('payment-mediums'),
	walledGardenLanding: appRoute('limited-landing'),
	rica: appRoute('rica'),
	stripeSaved: appRoute('stripe-saved'),
	thankYou: appRoute('thank-you'),
	wifiManagement: appRoute('wifi-management'),
	accountConfirmation: appRoute('account-confirmation'),
	accountSetup: appRoute('account-setup'),
	passwordReset: appRoute('password-reset'),
	backOffice: appRoute('back-office'),
	externalPaymentResult: appRoute('ext-pay'),
	orderSuccess: appRoute('order-success'),
	addressManagement: appRoute('address-management'),
};

export const BACK_OFFICE_ROUTES = {
	controls: appRoute('controls'),
	paymentManagement: appRoute('payment-management'),
	rulesEngine: appRoute('rules-engine'),
	knox: appRoute('knox'),
	security: appRoute('security'),
	notifications: appRoute('notifications'),
	portal: appRoute('portal'),
	// layout: appRoute('layout'),
};

export const ADDRESS_MANAGEMENT = {
	clientPremises: appRoute('client-premises'),
}

export const CONTROLS = {
	bulkServices: appRoute('bulk-services'),
	scheduler: appRoute('scheduler'),
	testRedirect: appRoute('payment-medium-capture/:serviceId'),
};

export const PAYMENT_MANAGEMENT = {
	firstPurchase: appRoute('first-purchase'),
	paymentMediums: appRoute('payment-mediums'),
	stripeSaved: appRoute('stripe-saved'),
	externalPaymentResult: appRoute('ext-pay'),
	paymentDetails: appRoute('payment-details'),
	portalPaymentHistory: appRoute('portal-payment-history'),
};

export const RULES_ENGINE = {
	billingSchemeConfig: appRoute('billing-scheme-config'),
};

export const NOTIFICATIONS = {
	landingPage: appRoute('landing-page'),
	requestList: appRoute('request-list'),
};

export const PORTAL = {
	loginPage: appRoute('login'),
	forgotPassword: appRoute('forgot-password'),
	checkEmail: appRoute('check-email'),
	resetPassword: appRoute('reset-password'),
	faq: appRoute('faq'),
	dashboardPage: appRoute('dashboard'),
	customerPage: appRoute('customers'),
	devices: appRoute('devices'),
	taskPage: appRoute('tasks'),
	manageProfilePage: appRoute('manage-profile'),
	// requestList: appRoute('request-list'),
};
export const DASHBOARD_TABS = {
	services: appRoute('services'),
	newOrders: appRoute('new-orders'),
	changes: appRoute('changes'),
	cancellations: appRoute('cancellations'),
	support: appRoute('support'),
	others: appRoute('others'),
	unassignedDevices: appRoute('unassigned-devices'),
	// requestList: appRoute('request-list'),
};
export const CUSTOMER_ROUTES = {
	addCustomerPage: appRoute(`add`),
	detailPage: appRoute(':id'),
};
