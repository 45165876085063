import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPagedResponse } from '@aex/ngx-toolbox';
import { IClientPremises, IClientPremisesRequest } from '@aex/shared/common-lib';
import { PremiseApi } from '../api';

@Injectable({
	providedIn: 'root',
})
export class AddressManagementService {

	constructor(private readonly http: HttpClient) { }

	public getClientPremisesFiltered(request: IClientPremisesRequest): Observable<IPagedResponse<IClientPremises>> {
		const url = PremiseApi.clientPremisesFiltered;
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

		return this.http.post<IPagedResponse<IClientPremises>>(url, request, { headers });
	}

	public updateClientPremises(clientPremisesId: number, clientPremises: IClientPremises): Observable<IClientPremises> {
		const url = PremiseApi.updateClientPremises(clientPremisesId);
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const payload = { client_premise: clientPremises };

		return this.http.put<IClientPremises>(url, payload, { headers });
	}
}
