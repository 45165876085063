import { IActionDto, KnoxRegistrationActionHelper, KNOX_ACTIONS } from "@aex/security/shared";

export class AdminNexusKnoxActionHelper{

  public static getSystemName() : string {
    return 'admin-nexus';
  }
  public static getSystemActions() : IActionDto[]{
    return [
      KnoxRegistrationActionHelper.RegisterAction(KNOX_ACTIONS.AdminSecurity, "Admin Security", "Admin Security"),
      KnoxRegistrationActionHelper.RegisterAction(KNOX_ACTIONS.ManageSecurity, "Manage Security", "Manage Security"),
      KnoxRegistrationActionHelper.RegisterAction(KNOX_ACTIONS.ReadSecurity, "Read Security", "Read Security"),
    ]
  }
}