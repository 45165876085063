import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve} from '@angular/router';
import { Observable } from 'rxjs';
import {
  IMaintainPersonUserAccessingRoleDto,
  RoleUserService,
  SecurityCompanyService,
} from "@aex/security/shared";


@Injectable()
export class GetManageRoleUsersResolver implements Resolve<IMaintainPersonUserAccessingRoleDto> {
	constructor(
			private readonly companyService: SecurityCompanyService,
			private readonly roleUserService: RoleUserService,
	) {	}

	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainPersonUserAccessingRoleDto> {
		const roleId = route.params["roleId"];
		const companyId = this.companyService.getCompanyId();
		return this.roleUserService.getManageRoleUsers(companyId, roleId);
	}

}

