import { StorageService } from '@aex/ngx-toolbox';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { IOrgUnitDto } from '../interfaces/knox';

@Injectable()
export class SecurityCompanyService {
	private readonly COMPANY_ID_KEY = 'company-id';
	private readonly COMPANY_NAME_KEY = 'company-name';
  private readonly COMPANY_OBJECT_KEY = 'company-object';
	protected _selectedCompanyId = '';
	protected _selectedCompanyName = '';
  protected _selectedCompanyDto: IOrgUnitDto = null;

	constructor(private readonly storageService: StorageService) { }

	public getCompanyId(): string {
		if (isNil(this._selectedCompanyId) || this._selectedCompanyId === '')
			this._selectedCompanyId = this.storageService.retrieve(
				this.COMPANY_ID_KEY,
				false,
			);

		return this._selectedCompanyId;
	}

	public setCompanyId(value: string): void {
		this._selectedCompanyId = value;
		this.storageService.store(this.COMPANY_ID_KEY, value, false, false);
	}

	public getCompanyName(): string {
		if (isNil(this._selectedCompanyName) || this._selectedCompanyName === '')
			this._selectedCompanyName = this.storageService.retrieve(
				this.COMPANY_NAME_KEY,
				false,
			);

		return this._selectedCompanyName;
	}
	public setCompanyName(value: string): void {
		this._selectedCompanyName = value;
		this.storageService.store(this.COMPANY_NAME_KEY, value, false, false);
	}

	public getCompanyDto(): IOrgUnitDto {
		if (isNil(this._selectedCompanyDto))
			this._selectedCompanyDto = JSON.parse(
        this.storageService.retrieve(
          this.COMPANY_OBJECT_KEY,
          false,
        ),
			);

		return this._selectedCompanyDto;
	}
	public setCompanyDto(value: IOrgUnitDto): void {
		this._selectedCompanyDto = value;
		this.storageService.store(this.COMPANY_OBJECT_KEY, value, false, false);
	}  
}
