import {
	ILiveMockConfig,
	LiveMockService,
	NavigationService,
	getFnoName,
	mapToNull,
} from '@aex/ngx-toolbox';
import {RegisterKnoxActionsService, SECURITY_ROUTES} from '@aex/security/shared';
import { ConfigApi } from '@aex/shared/apis';
import {
	AuthType,
	DEFAULT_CONFIG,
	ENVIRONMENT_CONFIG_TOKEN,
	IEnvFile,
	Operators,
	ParamMetaData,
	ROUTE_CONFIG,
	SERVER_CONFIG,
} from '@aex/shared/common-lib';
import {
	BaseAppConfigLoaderService,
	BaseConfigService,
} from '@aex/shared/root-services';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { merge } from 'lodash';
import { Observable, defer } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { IAdminNexusEnvironment } from '../../../environments/environment-type';
import { AdminNexusKnoxActionHelper } from '../../helpers/admin-nexus-knox-action-helper';

@Injectable()
export class AdminNexusConfigLoaderService extends BaseAppConfigLoaderService {
	constructor(
		http: HttpClient,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IAdminNexusEnvironment,
		private readonly navigationService: NavigationService,
		private readonly liveMockService: LiveMockService,
		private readonly configService: BaseConfigService,
    private readonly registerKnoxActionsService: RegisterKnoxActionsService,
	) {
		super(http, environment);
	}

	protected get adminNexusEnvironment(): IAdminNexusEnvironment {
		return this.environmentBase as IAdminNexusEnvironment;
	}

	protected override loadServerUrls(): void {
		SERVER_CONFIG.knoxUrl = this.adminNexusEnvironment.knoxUrl;
		SERVER_CONFIG.companyManagementServiceUrl = this.adminNexusEnvironment.companyManagementServiceUrl;
	}

	public override loadAppConfig(): Observable<void> {
		this.navigationService.startLoading();
		return this.http.get<IEnvFile>(ConfigApi.dynamicEnvironment, {
				params: new ParamMetaData({ authToken: AuthType.NONE }),
			}).pipe(
				switchMap((envConfig: IEnvFile) => {
					this.configService.fnoName = getFnoName(this.adminNexusEnvironment, envConfig.operatorResolution) ?? Operators.AutomationExchange;
					if (this.configService.fnoName)
						merge(this.adminNexusEnvironment, {
							operator: this.configService.fnoName,
						});

					merge(
						this.adminNexusEnvironment,
						envConfig.environments[this.configService.getConfigName()],
						this.adminNexusEnvironment.override,
					);
					this.configService.operatorBehaviorSubject.next(
						this.configService.operator,
					);

					return this.configService.getFnoConfig(
						this.configService.operator,
						this.configService.getConfigName(),
					);
				}),
				tap((fnoConfig) => {
					// load global config then override by environment
					if (fnoConfig) {
					}
					this.configService.startUpData = {
						...this.adminNexusEnvironment.override,
					};
					this.configService.configValues = this.configService.startUpData;
					this.initLoadAppConfig();
					ROUTE_CONFIG.startUrl = this.configService.startUpData.startUrl ?? SECURITY_ROUTES.login.name;
					// load server urls
					this.loadServerUrls();
					this.liveMockService.config = this.getLiveMockServiceConfig();

          // Register Knox Actions
          this.registerKnoxActionsService.registerKnoxActions(
            AdminNexusKnoxActionHelper.getSystemName(),
            AdminNexusKnoxActionHelper.getSystemActions(),
          ).subscribe();
				}),
				catchError(() =>
					this.configService.getFnoConfig(
						DEFAULT_CONFIG,
						this.configService.getConfigName(),
					),
				),
				mapToNull(),
				finalize(() => defer(() => this.navigationService.stopLoading())),
			);
	}

	protected override initLoadAppConfig(): void {}

	public override getLiveMockServiceConfig(): ILiveMockConfig {
		return {
			enabled: this.adminNexusEnvironment.mocksEnabled,
			serverUrls: [this.adminNexusEnvironment.knoxUrl],
			portNumbers: [],
		};
	}
}
