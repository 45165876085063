import {
	IMaintainOrgUnitTypeRoleDto,
	IOrgUnitTypeDto,
	KnoxApiService,
} from '@aex/security/shared';
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SECURITY_ROUTE_PARAMS } from "../helpers/security-route-parameters";
import { CompanyTypeRoleService } from "../services/company-type-role.service";

@Injectable()
export class GetOrgUnitTypesResolver implements Resolve<IOrgUnitTypeDto[]> {

	constructor(private readonly knoxApiService: KnoxApiService) { }

	public resolve(): Observable<IOrgUnitTypeDto[]> {
		return this.knoxApiService.getAllOrgUnitTypes();
	}
}

@Injectable()
export class GetOrgUnitTypeResolver implements Resolve<IOrgUnitTypeDto> {

	constructor(private readonly knoxApiService: KnoxApiService) { }

	public resolve(route: ActivatedRouteSnapshot): Observable<IOrgUnitTypeDto> {
		const orgUnitTypeId = route.params[SECURITY_ROUTE_PARAMS.companyTypeId];
		return this.knoxApiService.getOrgUnitType(orgUnitTypeId);
	}
}

@Injectable()
export class GetManageCompanyTypeRolesResolver implements Resolve<IMaintainOrgUnitTypeRoleDto> {
	constructor(private readonly companyTypeRoleService: CompanyTypeRoleService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<IMaintainOrgUnitTypeRoleDto> {
		const orgUnitTypeId = route.params[SECURITY_ROUTE_PARAMS.companyTypeId];
		return this.companyTypeRoleService.getManageCompanyTypeRoles(orgUnitTypeId);
	}
}
